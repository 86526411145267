var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"baseList",staticClass:"base-list",class:{
    'arr-list': _vm.isArrData,
  },style:(_vm.listHeight ? `height: ${_vm.listHeight}px;` : '')},[_c(_vm.refresh ? 'van-pull-refresh' : 'div',{tag:"components",attrs:{"success-text":_vm.$t('RefreshSuccessful')},on:{"refresh":() => {
        _vm.$emit('refresh');
        _vm.listRefresh();
      }},model:{value:(_vm.listCore.refreshing),callback:function ($$v) {_vm.$set(_vm.listCore, "refreshing", $$v)},expression:"listCore.refreshing"}},[_c('van-list',{attrs:{"finished":_vm.listCore.finished,"finished-text":_vm.isArrData ? '' : _vm.$t(_vm.listCore.finishedText),"error":_vm.listCore.error,"error-text":_vm.$t('RequestFailedClickToReload')},on:{"update:error":function($event){return _vm.$set(_vm.listCore, "error", $event)},"load":() => {
          _vm.listCore.load().then(() => {
            _vm.$emit('change', _vm.listCore);
          });
        }},scopedSlots:_vm._u([{key:"finished",fn:function(){return [(!_vm.listCore.data.length)?_c('span'):_vm._e()]},proxy:true}]),model:{value:(_vm.listCore.loading),callback:function ($$v) {_vm.$set(_vm.listCore, "loading", $$v)},expression:"listCore.loading"}},[_c('van-skeleton',{staticStyle:{"width":"85%","margin":"auto"},attrs:{"title":"","row":3,"loading":!_vm.listCore.inited}},[_vm._t("list",function(){return _vm._l((_vm.listCore.data),function(item,k){return _c('div',{key:k,attrs:{"title":item}},[_vm._t("default",null,{"item":item})],2)})},{"data":_vm.listCore.data})],2),(!_vm.listCore.data.length && !_vm.listCore.loading)?_c('div',{staticStyle:{"width":"109px","margin":"auto","margin-top":"40px"}},[_c('van-empty',{attrs:{"description":_vm.$t('noData')}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }