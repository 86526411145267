var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-cont"},[_c('transition',{attrs:{"name":"top-hidden"}},[(_vm.$route.meta && _vm.$route.meta.tabHeader)?_c('van-nav-bar',{attrs:{"fixed":""},on:{"click-left":() => {
          _vm.menuShow = true;
        },"click-right":() => {
          _vm.$router.push({
            name: 'set',
          });
        }},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('div',{staticClass:"layout-icon-wrap"},[_c('svg-icon',{staticClass:"layout-icon",attrs:{"iconClass":"list"}})],1)]},proxy:true},{key:"right",fn:function(){return [_c('div',{staticClass:"layout-icon-wrap"},[_c('svg-icon',{staticClass:"layout-icon",attrs:{"iconClass":"set"}})],1)]},proxy:true},{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.curWalletChain.chainName)+" ")]},proxy:true}],null,false,440732545)}):_vm._e()],1),_c('AppMain'),_c('transition',{attrs:{"name":"bottom-hidden"}},[(_vm.$route.meta && _vm.$route.meta.tab)?_c('van-tabbar',{attrs:{"route":""}},[_c('van-tabbar-item',{attrs:{"icon":"home-o","replace":"","to":{ name: 'home' }}},[_vm._v("home")]),_c('van-tabbar-item',{attrs:{"icon":"search","replace":"","to":{ name: 'about' }}},[_vm._v("about")]),_c('van-tabbar-item',{attrs:{"icon":"friends-o","replace":"","to":{ name: 'my' }}},[_vm._v("my")])],1):_vm._e()],1),_c('van-popup',{staticStyle:{"height":"100%","width":"80%"},attrs:{"position":"left"},model:{value:(_vm.menuShow),callback:function ($$v) {_vm.menuShow=$$v},expression:"menuShow"}},[_c('div',{staticClass:"chains-wrap"},[_c('div',{staticClass:"chains-title"},[_vm._v(_vm._s(_vm.$t("ChooseAWallet")))]),_c('div',{staticClass:"chains"},_vm._l((_vm.walletChains),function(item,k){return _c('div',{key:k,staticClass:"chains-item",on:{"click":function($event){return _vm._selWalletChain(item)}}},[_c('div',{staticClass:"chains-item-before",style:({
              'background-image': `url(${item.logo})`,
            })}),_c('div',{staticClass:"chains-item-text"},[_c('div',{staticClass:"chains-item-text-top"},[_vm._v(_vm._s(item.chainName))]),_c('TextHms',{staticClass:"chains-item-text-bottom"})],1),_c('div',{staticClass:"chains-item-logo"},[_c('BaseImg',{attrs:{"src":item.logo}})],1)])}),0)])]),_c('van-notify',{attrs:{"type":(_vm.showAlertMsg && _vm.showAlertMsg.type) || 'danger'},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},[_c('div',{staticClass:"t-notify"},[_c('div',{staticClass:"t-notify-text"},[_c('span',[_vm._v(_vm._s((_vm.showAlertMsg && _vm.showAlertMsg.msg) || _vm.$t("noPwdInfo")))]),_c('span',{on:{"click":() => {
              _vm.showAlertMsg && _vm.showAlertMsg.func && _vm.showAlertMsg.func();
            }}},[(_vm.showAlertMsg.toMsg)?_c('span',[_vm._v(" "+_vm._s(_vm.showAlertMsg.toMsg)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("noPwdInfoTo"))+" ")]),_c('van-icon',{attrs:{"name":"arrow"}})],1)]),_c('van-icon',{attrs:{"name":"cross"},on:{"click":() => {
            _vm.$alert('');
          }}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }