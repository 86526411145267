import axios from "axios";
import {
  // Dialog,
  Notify,
} from "vant";
import store from "@/store";
import { getToken, TokenKey } from "@/utils/auth";
import { encrypt, decrypt } from "@/utils/jm";
import { i18n } from "@/langs/index";
import router from "@/router";

export const getCodeMsg = (code, msg) => {
  if (code === "10040" && msg === "不能向自己錢包轉帳") {
    return i18n.tc("不能向自己錢包轉帳");
  }
  const i18nStr =
    {
      200: "resCodes.200",
      400: "resCodes.400",
      401: "resCodes.401",
      402: "resCodes.402",
      403: "resCodes.403",
      500: "resCodes.500",
      1000: "resCodes.1000",
      10010: "resCodes.10010",
      10020: "resCodes.10020",
      10030: "resCodes.10030",
      10040: "resCodes.10040",
      10050: "resCodes.10050",
      10060: "resCodes.10060",
      11000: "resCodes.11000",
      11010: "resCodes.11010",
      11020: "resCodes.11020",
      11030: "resCodes.11030",
      11040: "resCodes.11040",
      12000: "resCodes.12000",
      12010: "resCodes.12010",
      12020: "resCodes.12020",
      12030: "resCodes.12030",
      12040: "resCodes.12040",
      12050: "resCodes.12050",
      12060: "resCodes.12060",
      12070: "resCodes.12070",
      12080: "resCodes.12080",
      12090: "resCodes.12090",
      12100: "resCodes.12100",
      12110: "resCodes.12110",
      12120: "resCodes.12120",
      11050: "resCodes.11050",
    }[code] || "";
  return i18n.tc(i18nStr);
};

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 50 * 1000,
});

service.interceptors.request.use(
  (config) => {
    if (!config.headers[TokenKey] && store.getters.token) {
      config.headers[TokenKey] = "Bearer " + getToken();
    }
    if (config.data) {
      config.data = { content: encrypt({ ...config.data, time: Date.now() }) };
      if (["development"].includes(process.env.NODE_ENV)) {
        console.log(config.url, decrypt(config.data.content));
      }
    } else {
      config.params = {
        content: encrypt({ ...config.params, time: Date.now() }),
      };
      if (["development"].includes(process.env.NODE_ENV)) {
        console.log(config.url, decrypt(config.params.content));
      }
    }
    return config;
  },
  // 处理请求错误
  (error) => {
    // 为调试
    console.log(error, "init");
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code !== 200) {
      //   public const LOGIN_EXPIRED = 10006; // 登录失效
      //     public const LOGIN_BLACKLIST = 10007; // 黑名单
      //     public const USER_FORBIDDEN = 10008; // 账户被禁
      // public const LOST_LOGIN = 10001; //  登录失效
      // 401 token验证失败
      if ([401, 10006, 10007, 10008, 10001].includes(res.code)) {
        Notify({
          message: i18n.tc("LinkFailure"),
          type: "danger",
          duration: 5 * 1000,
        });
        store.dispatch("user/logout").then(() => {
          router.replace(`/login`);
        });
      } else {
        const fItem = [
          ["code0", "發送失敗"],
          ["code1", "send code unknow error"],
          ["code2", "sms send error"],
          ["code3", "驗證失敗"],
          ["code4", "驗證碼錯誤"],
          ["code5", "驗證碼已過期"],
          ["code7", "动账密码错误"],
        ].find((item) => {
          return item[1] === res.message;
        });
        const match6arr = ((res && res.message) || "").match(
          /發送頻率太高,請在(.+)秒之後再試試/
        );
        const match6 = match6arr && match6arr.length && match6arr[1];
        const phoneErrArr = ((res && res.message) || "").match(
          /sms send error\[Invalid phone number:(.+)\]/
        );
        const phoneErr = phoneErrArr && phoneErrArr.length && phoneErrArr[1];
        console.log(phoneErr, 'phoneErrArrphoneErrArrphoneErrArr')
        const str =
          (phoneErr && `${i18n.tc("InvalidPhoneNumber")}`) ||
          (match6 &&
            `${this.$i18n.tc("code6part1")} ${match6} ${i18n.tc(
              "code6part2"
            )}`) ||
          (fItem && fItem[0] && i18n.tc(fItem[0])) ||
          getCodeMsg(res.code, res.message) ||
          res.message ||
          "Error";
        ![11050].includes(res.code) &&
          Notify({
            message: str,
            type: "danger",
            duration: 5 * 1000,
          });
      }
      return Promise.reject(res || "Error");
    } else {
      // 请求成功才从后台返回的信息拿token
      const token = response.headers["new-token"];
      if (token) {
        const tokenTime = response.headers["new-token-ttl"];
        store.dispatch("user/setToken", {
          token: token.replace("Bearer ", ""),
          tokenTime,
        });
      }
      return res;
    }
  },
  (error) => {
    console.log("err" + error);
    if (
      error.code === "ECONNABORTED" ||
      error.message === "Network Error" ||
      error.message.includes("timeout")
    ) {
      Notify({
        message: i18n.tc(
          window.navigator.onLine ? "networkTimeout" : "noNetwork"
        ),
        type: "danger",
        duration: 5 * 1000,
      });
      return Promise.reject(error);
    }
    Notify({
      message:
        getCodeMsg(error.code, error.message) ||
        (error && error.message) ||
        "Error",
      type: "danger",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
