import request from "@/utils/request.js";

export function initUser(data){
  return request({
    method: 'post',
    url:'/api/wallet/initUser',
    data
  });
}

export function getCurrency(data){
  return request({
    method: 'post',
    url:'/api/wallet/getCurrency',
    data
  });
}

export function bindPhone(data){
  return request({
    method: 'post',
    url:'/api/wallet/bindPhone',
    data
  });
}

export function bindEmail(data){
  return request({
    method: 'post',
    url:'/api/wallet/bindEmail',
    data
  });
}

export function modifyPhone(data){
  return request({
    method: 'post',
    url:'/api/wallet/modifyPhone',
    data
  });
}

export function modifyEmail(data){
  return request({
    method: 'post',
    url:'/api/wallet/bindEmail',
    data
  });
}

export function getUserInfo(data){
  return request({
    method: 'post',
    url:'/api/wallet/getUserInfo',
    data
  });
}

export function getValidVerifyMethod(data){
  return request({
    method: 'post',
    url:'/api/wallet/bindEmail',
    data
  });
}