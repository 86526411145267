<template>
  <div
    v-iscroll="{
      option: tOption,
      instance: getInstance
    }"
    class="base-scroll"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      default: () => {}
    },
    listenScroll: {
      type: [Function],
      default: null
    },
    pullup: {
      type: [Function],
      default: null
    },
    pulldown: {
      type: [Function],
      default: null
    },
    beforeScroll: {
      type: [Function],
      default: null
    },
  },
  data() {
    return {
      tOption: {
        scrollX: true,
        scrollY: true,
        scrollbars: true,
        interactiveScrollbars: true,
        iScrollBothScrollbars: true,
        autoCountX: true
      },
      scroll: null,
      curIndex: 0,
      runing: false,
      hasOver: false
    }
  },
  watch: {
    option: {
      handler(val) {
        this.tOption = {
          ...this.tOption,
          ...val
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getInstance(iscrollObj) {
      this.scroll = iscrollObj
      this.scroll.on('scrollStart', this.scrollStart)
      this.scroll.on('scrollEnd', this.getCurentIdx)
      this.scroll.on('refresh', () => {
        this.hasOver = this.getHasHover()
      })
      window.console.log(this.listenScroll, this.pullup, this.pulldown, this.beforeScroll, 'oooo')
       // 是否派发滚动事件
       if (this.listenScroll) {
          this.scroll.on('scroll', (pos) => {
            this.listenScroll&&this.listenScroll(pos)
            this.isShowBT = pos.y < -1000;
          })
        }
        // 是否派发滚动到底部事件，用于上拉加载
        if (this.pullup) {
          this.scroll.on('scrollEnd', () => {
            // 滚动到底部
            if (this.scroll.y <= (this.scroll.maxScrollY + 50)) {
              this.pullup&&this.pullup()
            }
          })
        }
        // 是否派发顶部下拉事件，用于下拉刷新
        if (this.pulldown) {
          this.scroll.on('touchEnd', (pos) => {
            // 下拉动作
            if (pos.y > 50) {
              this.pulldown&&this.pulldown()
            }
          })
        }
        // 是否派发列表滚动开始的事件
        if (this.beforeScroll) {
          this.scroll.on('beforeScrollStart', () => {
            this.beforeScroll&&this.beforeScroll()
          })
        }
    },
    scrollStart() {
      this.runing = true
    },
    getHasHover() {
      return (this.scroll && this.scroll.$intervalArr || []).some(item => {
        return this.scroll.$boxWidth < item[1].offsetLeft
      })
    },
    getCurentIdx() {
      this.runing = false
      this.curIndex = this.scroll.$intervalArr.findIndex(item => {
        return this.scroll.x >= -item[1].offsetLeft
      })
    },
    next() {
      if (this.runing) { return }
      this.curIndex = Math.min(this.curIndex + 1, this.scroll.$intervalArr.length - 1)
      this.scrollTo()
    },
    back() {
      if (this.runing) { return }
      this.curIndex = Math.max(this.curIndex - 1, 0)
      this.scrollTo()
    },
    scrollTo() {
      const xLeft = this.scroll.$intervalArr[this.curIndex][1].offsetLeft
      const maxLeft = Math.abs(this.scroll.$boxWidth - this.scroll.$innerWidth)
      this.scroll.scrollTo(-Math.min(xLeft, maxLeft), 0, 300)
    },
    refresh() {
      // 代理better-scroll的refresh方法
      this.scroll && this.scroll.refresh()
    },
  }
}
</script>
<style lang="scss" scoped>
.base-scroll{
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  overflow: hidden;
}

</style>
