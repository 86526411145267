import Vue from "vue";
import VueI18n from "vue-i18n";
import { Locale } from "vant";
import VantZhCN from "vant/lib/locale/lang/zh-CN";
import VantZhTW from "vant/lib/locale/lang/zh-TW";
import VantEnUS from "vant/lib/locale/lang/en-US";
import enUS from "./en";
import zhCN from "./zhCN";
import zhTW from "./zhTW";
import mobilePhoneAreaEn from "./mobilePhoneArea/en";
import mobilePhoneAreaZhCN from "./mobilePhoneArea/zhCN";
import mobilePhoneAreaZhTW from "./mobilePhoneArea/zhTW";
import resCodesEN from "./resCodes/en";
import resCodesZhCN from "./resCodes/zhCN";
import resCodesZhTW from "./resCodes/zhTW";
import Cookies from "js-cookie";

const toObjMessages = (val) => {
  const obj = {};
  mobilePhoneAreaEn.forEach((item) => {
    const enStr = item.label;
    enStr.replace(/[^a-zA-Z]*/g, "");
    const fArr = val.filter((citem) => item.value === citem.value);
    let fItem;
    if (fArr.length > 1) {
      fItem = fArr.find((citem) => citem.key === item.key) || "";
    } else {
      fItem = fArr[0] || "";
    }
    obj[`${item.key || enStr}`] = fItem.label;
  });
  return obj;
};

Vue.use(VueI18n);
export const langs = [
  {
    title: "简体中文",
    locale: [
      "zhCN",
      {
        ...zhCN,
        phoneAreas: {
          ...toObjMessages(mobilePhoneAreaZhCN),
        },
        resCodes: resCodesZhCN,
      },
    ],
    vantLocale: ["zh-CN", VantZhCN],
  },
  {
    title: "English",
    locale: [
      "en",
      {
        ...enUS,
        phoneAreas: {
          ...toObjMessages(mobilePhoneAreaEn),
        },
        resCodes: resCodesEN,
      },
    ],
    vantLocale: ["en-US", VantEnUS],
  },
  {
    title: "繁體中文",
    locale: [
      "zhTW",
      {
        ...zhTW,
        phoneAreas: {
          ...toObjMessages(mobilePhoneAreaZhTW),
        },
        resCodes: resCodesZhTW,
      },
    ],
    vantLocale: ["zh-TW", VantZhTW],
  },
];
// 组合语言包对象
const messages = {};
langs.forEach((item) => {
  const [key, val] = item.locale;
  messages[key] = val;
});
const curLang = Cookies.get("lang") || langs[1].locale[0];
// 创建 VueI18n 实例，并为 messages 和 locale 属性赋值
export const i18n = new VueI18n({
  messages,
  locale: curLang,
});
// 多语言切换
export const Locals = (lang) => {
  const fItem = langs.find((item) => item.locale[0] === lang);
  if (!fItem) {
    // console.error('未找到语言包！', langs)
    return;
  }
  i18n.locale = fItem.locale[0];
  i18n.localeTitle = fItem.title;
  Locale.use(...fItem.vantLocale);
  Cookies.set("lang", fItem.locale[0]);
};
Locals(curLang);
