var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"currency-item"},[(_vm.imgType === 'img')?_c('base-img',{staticClass:"currency-item-img",attrs:{"zw":"","src":_vm.item.img}}):_c('svg-icon',{staticClass:"currency-item-img",class:{
      red: !_vm.item.isIn,
      green: _vm.item.isIn,
    },attrs:{"iconClass":_vm.item.img}}),_c('div',{staticClass:"currency-item-msg"},[_c('div',{staticClass:"currency-item-left"},[_c('TextHms',{staticClass:"currency-item-title",attrs:{"cont":_vm.item.name}}),_c('div',{staticClass:"currency-item-info"},[_vm._v(" "+_vm._s(_vm.item.date)+" ")])],1),_c('div',{staticClass:"currency-item-right"},[_c('TextAutoSize',{staticClass:"currency-item-undulating",class:{
          del: _vm.item.isIn !== undefined && !_vm.item.isIn,
          add: _vm.item.isIn !== undefined && _vm.item.isIn,
        },attrs:{"cont":(_vm.item.isIn !== undefined ? (_vm.item.isIn ? '+ ' : '- ') : '') +
            _vm.item.balance || ''}}),_c('div',{staticClass:"currency-item-info"},[_vm._v(_vm._s(_vm.item.info))])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }