<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { AppMixin as TransferDataAppMixin } from "@/mixins/TransferData.js";
// import {mapActions} from 'vuex'

export default {
  name: "App",
  mixins: [TransferDataAppMixin],
  mounted() {
    // const token = this.$route.query.token;
    // if(!['', null, undefined].includes(this.$store.state.token)){
    // 	this.getUserInfo().then(()=>{
    // 		this.$router.replace({
    //       name: 'home'
    //     })
    // 	})
    // }else{
    //   this.$router.replace({
    //     name: 'home'
    //   })
    // }
  },
  // watch: {
  //   $route: {
  //     immediate: true,
  //     handler(newValue) {
  //       console.log(newValue, "====");
  //     },
  //   },
  // },
  methods: {
    // ...mapActions('user', ['getUserInfo', 'setToken']),
  },
};
</script>

<style lang="scss" scoped>
#app {
  height: 100%;
  overflow: hidden;
}
</style>
