// import '@/styles/loading.scss'
import { i18n } from "@/langs";
import router from "@/router";
import { faultMsg } from "@/router/fault";

let instance = null;

const clearDom = () => {
  const domArr = document.querySelectorAll(".module-loading") || [];
  [...domArr].forEach((dom) => {
    dom && dom.remove();
  });
};
const mLoading = {
  start: function () {
    clearDom();
    const loadingText = i18n.tc("ModuleLoading");
    instance = document.createElement("div");
    instance.className = "module-loading";
    instance.innerHTML = `
        <div class="module-loading-inner">
          <div class="loading"></div>
          <div class="module-loading-text">${loadingText}</div>
        </div>
    `;
    document.body.appendChild(instance);
  },
  end: (componentImport) => {
    if (componentImport) {
      const errorBox = document.createElement("div");
      errorBox.className = "module-loading-error";
      const errorText = document.createElement("div");
      errorText.className = "module-loading-error-text";
      errorText.innerHTML = i18n.tc("ModuleLoadingFailed");
      const btn = document.createElement("div");
      btn.className = "module-loading-btn";
      btn.textContent = i18n.tc("Reload");
      btn.onclick = () => {
        mLoading.start();
        componentImport()
          .then(() => {
            router.push({
              ...faultMsg,
            });
            clearDom();
            setTimeout(() => {
              clearDom();
            }, 300);
          })
          .catch(() => {
            mLoading.end(componentImport);
          });
      };
      const btn2 = document.createElement("div");
      btn2.className = "module-loading-btn";
      btn2.textContent = i18n.tc("return");
      btn2.onclick = () => {
        clearDom();
        setTimeout(() => {
          clearDom();
        }, 300);
      };
      errorBox.appendChild(errorText);
      errorBox.appendChild(btn2);
      errorBox.appendChild(btn);
      const domArr = document.querySelectorAll(".module-loading-inner") || [];
      [...domArr].forEach((dom) => {
        dom.innerHTML = "";
        dom.appendChild(errorBox);
      });
    } else {
      clearDom();
      setTimeout(() => {
        clearDom();
      }, 300);
    }
  },
};

export default mLoading;
