import { getUserInfo } from "@/api/user";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { resetRouter } from "@/router";
import Cookies from "js-cookie";

const getDefaultState = () => {
  return {
    token: getToken(),
    user: (Cookies.get("user") && JSON.parse(Cookies.get("user"))) || '',
  };
};

const state = getDefaultState();

const mutations = {
  SET_USER(state, user) {
    state.user = user;
    Cookies.set("user", JSON.stringify(user));
  },
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, { token, tokenTime }) => {
    state.token = token;
    setToken(token, tokenTime);
  },
  REMOVE_USER(state){
    state.user = ''
    Cookies.remove("user");
  }
};

const actions = {
  setUser({ commit }, user) {
    commit("SET_USER", user);
  },
  // 获取用户信息
  getUserInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getUserInfo()
        .then((response) => {
          const { data } = response;
          if(state.user&&(data.uuid!==state.user.uuid)){
            commit('app/SEL_WALLET_CHAIN', "", { root: true })
            Cookies.remove("curWalletChain");
          }
          commit("SET_USER", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 用户登出
  logout({ commit, dispatch }) {
    dispatch("app/clearCookies", {}, { root: true });
    removeToken();
    resetRouter();
    commit("RESET_STATE");
  },

  setToken({ commit }, { token, tokenTime }) {
    commit("SET_TOKEN", { token, tokenTime });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
