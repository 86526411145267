import Vue from "vue";
import { Notify } from "vant";
import { Form } from "vant";
import { Field } from "vant";
import { Button } from "vant";
import { Tabbar, TabbarItem } from "vant";
import { NavBar } from "vant";
import { Sticky } from "vant";
import { Image as VanImage } from "vant";
import { ActionSheet } from "vant";
import { Icon } from "vant";
import { Popup } from "vant";
import { Cell, CellGroup } from "vant";
import { List } from "vant";
import { PullRefresh } from "vant";
import { Lazyload } from "vant";
import { Tab, Tabs } from "vant";
import { Toast } from "vant";
import { RadioGroup, Radio } from "vant";
import { CountDown } from "vant";
import { PasswordInput, NumberKeyboard } from "vant";
import { Search } from "vant";
import { Checkbox, CheckboxGroup } from "vant";
import { Empty } from "vant";
import { Skeleton } from "vant";
import { Loading } from "vant";
import { Dialog } from "vant";

Vue.use(Dialog);
Vue.use(Loading);
Vue.use(Skeleton);
Vue.use(Empty);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Search);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(CountDown);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Toast);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Lazyload);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Cell).use(CellGroup);
Vue.use(Popup);
Vue.use(Icon);
Vue.use(ActionSheet);
Vue.use(VanImage);
Vue.use(Sticky);
Vue.use(NavBar);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Button);
Vue.use(Form);
Vue.use(Field);
Vue.use(Notify);
