<template>
  <div ref="text" class="text-hms" :title="cont">
    <span ref="nm" class="text-hms-nm"></span>
    <TextOver>{{ cont }}</TextOver>
  </div>
</template>

<script>
import { debounce } from "@/utils/debounce";

function getStyle(obj, attr) {
  if (obj.currentStyle) {
    return obj.currentStyle[attr];
  } else {
    return document.defaultView.getComputedStyle(obj, null)[attr];
  }
}
export default {
  props: {
    cont: {
      type: [String, Number],
      default: "",
    },
    wrap: {
      type: Boolean,
      default: false,
    },
    min: {
      type: [String, Number],
      default: 12,
    },
    max: {
      type: [String, Number],
      default: 16,
    },
  },
  data() {
    return {
      resizeObserver: null,
    };
  },
  mounted() {
    this.addListener();
  },
  activated() {
    this.addListener();
  },
  beforeDestroy() {
    this.removeListener();
  },
  deactivated() {
    this.removeListener();
  },
  methods: {
    addListener() {
      this.resizeObserver = new ResizeObserver(() => {
        debounce(this, this.countShowNum)();
      });
      // 监听对应的dom
      this.resizeObserver.observe(this.$refs.text);
    },
    removeListener() {
      this.resizeObserver.unobserve(this.$refs.text);
    },
    countShowNum() {
      this.$nextTick(() => {
        if (!this.$refs.nm) {
          return;
        }
        const fontFamily = getStyle(this.$refs.nm, "fontFamily");
        const unit = getStyle(this.$refs.nm, "fontSize").replace("px", "");
        const minUnit = (this.min / 37.5) * unit;
        const maxUnit = (this.max / 37.5) * unit;
        const boxW = this.$refs.text.getBoundingClientRect().width;
        function getMaxFontSize(
          text,
          containerWidth,
          minSize,
          maxSize,
          fontFamily
        ) {
          let canvas = document.createElement("canvas");
          let context = canvas.getContext("2d");
          minSize = minSize || 12;
          maxSize = maxSize || 50;
          for (var i = minSize; i <= maxSize; i++) {
            context.font = i + "px " + fontFamily;
            let detail = context.measureText(text);
            let width = detail.width;
            if (width === containerWidth) {
              return i;
            }
            if (width > containerWidth) {
              return i - 1;
            }
          }
          return i > maxSize ? maxSize : i;
        }
        const size = getMaxFontSize(
          this.cont,
          boxW,
          minUnit,
          maxUnit,
          fontFamily
        );
        this.$refs.text.style.fontSize = size / unit + "rem";
      });
    },
  },
  watch: {
    cont: {
      immediate: true,
      handler() {
        debounce(this, this.countShowNum)();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.text-hms {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  .text-hms-inner {
    white-space: nowrap;
    &.text-hms-hidden {
      position: absolute;
      left: 0;
      height: 0;
      line-height: 0;
      overflow: hidden;
      opacity: 0;
    }
  }
}
.text-hms-nm {
  position: absolute;
  font-size: 37.5px;
}
</style>
