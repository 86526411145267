const oConfig = {
  layout: "total, sizes, prev, pager, next, jumper",
  pageSizes: [5, 10, 20, 30, 40],
  pageSize: 5,
  currentPage: 1,
  total: 0,
  loading: false,
  data: [],
  info: "NoData",
  refreshing: false,
  finished: false,
  finishedText: "ThereSNoMore",
  error: false,
  inited: false,
  getData: () => {},
  format: (res) => {
    return {
      list: res.data.data,
      total: res.data.total,
    };
  },
};

class listHelper {
  oConfig = oConfig;
  loadTimeStart = 0;
  loadNum = 0;
  constructor(options) {
    for (let i in oConfig) {
      oConfig[i] = ![undefined, null].includes(options[i])
        ? options[i]
        : oConfig[i];
      this[i] = oConfig[i];
    }
  }
  async load() {
    this.loading = true;
    if (Date.now() - this.loadTimeStart > 1000) {
      this.loadTimeStart = Date.now();
      this.loadNum = 0;
    }
    if (this.loadNum >= 15) {
      this.fail("请求次数过多，强制停止！");
      return;
    }
    this.loadNum++;
    this.info = "NoData";
    if (Array.isArray(this.getData)) {
      this.data = this.fill(this.getData);
      this.total = this.getData.length;
      this.loading = false;
      this.finished = true;
      this.inited = true;
      return;
    }
    try {
      const res = await this.getData(this.currentPage, this.pageSize)
        .catch((res) => {
          this.fail("请求报错了");
          console.error(res);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
            this.inited = true;
          }, 50);
        });
      if (!Array.isArray(res)) {
        if (!(res && res.code == 200)) {
          console.log(res, "resresres");
          this.fail("返回状态不对");
          return;
        }
        const { list, total } = this.format(res);
        this.data = this.fill([...this.data, ...list]);
        this.total = total;
        if (this.data.length >= this.total) {
          this.finished = true;
          return;
        }
        this.currentPage++;
      } else {
        this.data = this.fill(res);
        this.total = res.length;
        this.finished = true;
      }
    } catch (e) {
      this.fail("处理format报错了");
      this.loading = false;
      console.error(e);
    }
  }
  fill(preList) {
    return preList.map((item) => {
      const obj = {};
      obj.$data = item;
      for (let i in item) {
        if (["", undefined, null].includes(item[i])) {
          obj[i] = "";
          continue;
        }
        obj[i] = item[i];
      }
      return obj;
    });
  }
  async refresh() {
    this.resetOptions();
    this.refreshing = true;
    await this.load();
    this.refreshing = false;
  }
  resetOptions() {
    for (let i in oConfig) {
      this[i] = oConfig[i];
    }
  }
  fail(errType) {
    console.error("errType", errType);
    this.error = true;
    this.inited = true;
    this.info = "请求失败";
  }
}

export default listHelper;
