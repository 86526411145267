<template>
  <div>
    <div>
      {{
        val | arrItem({ list: phoneAreaList, name: "label", value: "value" })
      }}
    </div>
    <BaseAlert ref="alert" :close="false" @close="onCancel">
      <div class="search-wrap">
        <div class="search">
          <van-search
            v-model="search"
            :placeholder="$t('PleaseEnterSearchKeywords')"
          />
        </div>
        <div class="search-body">
          <BaseList
            :options="{ getData: filterPhoneAreaList }"
            v-slot="{ item }"
          >
            <div class="phone-area" @click="selItem(item.$data)">
              <div class="phone-area-label">{{ item.label }}</div>
              <div class="phone-area-value">{{ item.value }}</div>
            </div>
          </BaseList>
        </div>
      </div>
    </BaseAlert>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    val: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    ...mapGetters(["phoneAreaList"]),
    filterPhoneAreaList() {
      return [...this.phoneAreaList]
        .sort((a, b) => {
          return (a.value + "").localeCompare(b.value + "");
        })
        .filter((item) => {
          return item.label.match(this.search) || item.value.match(this.search);
        });
    },
  },
  methods: {
    selItem(data) {
      this.$emit("update:val", data.value);
      this.onCancel();
    },
    onCancel() {
      this.search = "";
      this.hide();
    },
    show() {
      this.$refs.alert.show();
    },
    hide() {
      this.$refs.alert.hide();
    },
  },
};
</script>

<style scoped lang="scss">
.search-wrap {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 180px);
  .search {
    padding-top: 18px;
    .van-search {
      padding-bottom: 8px;
    }
    .van-cell {
      padding-top: 9px;
      padding-bottom: 9px;
    }
    .van-search__content {
      background: #f7f7f7;
      border-radius: 21px;
    }
  }
  .search-body {
    flex: 1;
    overflow: auto;
  }
  .phone-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    height: 55px;
    background: rgba(143, 146, 161, 0.08);
    margin-bottom: 1px;
    font-size: 18px;
    font-weight: 400;
    color: #030319;
    line-height: 25px;
    text-align: center;
    .phone-area-label {
      flex: 1;
    }
    .phone-area-value {
      width: 120px;
    }
  }
}
</style>
