export default {
  ModuleLoading: "Loading...",
  ModuleLoadingFailed: "Loading failed",
  Reload: "Reload",
  return: "Return",

  RefreshSuccessful: `Refresh successful`,
  noData: `No data`,
  close: `Close`,
  AssetDetails: "Asset Details",
  ViewAssetDetails: "View asset details",
  wallet: "wallet",
  langTitle: "English",
  myWallet: "My wallet",
  Set: "Settings",
  securitySetting: "Security Settings",
  Valuation: "Pricing",
  AddressBook: "Address Book",
  Language: "Language",
  USD: "US dollars",
  NTD: "Taiwan Dollar",
  SetLanguages: "Language settings",
  SetValuations: "Pricing Settings",
  conversionOfWalletAssets: "Conversion of Wallet Assets",
  Cancel: "Cancel",

  ChooseAWallet: `Choose a wallet`,
  UnboundInfo: `The current account is not bound with contact information. To ensure the security of your account, please bind it`,
  Recharge: `Recharge`,
  TransferOut: `Transfer out`,
  TransferIn: `Transfer in`,
  exchange: `Exchange`,
  asset: `Asset`,
  ThereSNoMore: `There's no more`,
  Loading: `Loading`,
  NoData: `No data`,
  RequestFailedClickToReload: "Request failed, click to reload",
  SelectRechargeCurrency: "Select recharge currency",
  Currency: "Currency",

  RechargeCurrency: "Recharge currency",
  RechargeQuantity: "Recharge quantity",
  available: "available",
  prompt: "Prompt",
  RechargePrompt1: `1. To enhance the security of your funds, stay away from others when entering your password`,
  RechargePrompt2: `2. Please carefully select your payment network, otherwise the payment assets cannot be retrieved`,
  RechargePrompt3: `3. Please ensure account security to prevent information from being tampered with or leaked`,

  RechargeTo: `Recharge to`,
  PleaseSelectACurrency: `Please select a currency`,
  PleaseEnterTheQuantityThatNeedsToBeRecharged: `Please enter the quantity that needs to be recharged`,
  InsufficientAvailableBalance: `Insufficient available balance`,
  ConfirmTransfer: `Confirm transfer`,
  ToAccountNetwork: `To account network`,
  ArrivalAddress: `Arrival address`,
  TransferQuantity: `Transfer quantity`,
  HandlingFees: `Handling fees`,
  ConfirmRecharge: `Confirm recharge`,
  SecurityVerification: `Security verification`,
  MobileAccountPassword: `Mobile account password`,
  Send: "Send",

  PleaseEnterTheDeliveryAddress: "Please enter the delivery address",
  PleaseEnterTheCorrectDeliveryAddress: "Please enter the correct delivery ",
  ExceededYourCurrentBalance: "Exceeded your current balance",
  NotSetSetUpNow:
    "No account password has been set, and assets cannot be transferred out; Set Up Now",
  PleaseEnterTheTransferAmount: "Please enter the transfer amount!",
  PleaseEnterTheTransferAddress: "Please enter the transfer address!",

  network: `network`,
  TransferAmount: `Transfer amount`,
  MinimumTransferAmount: `Minimum transfer amount`,
  NetworkHandlingFees: `Network handling fees`,
  Transfer: `Transfer`,
  confirm: `confirm`,
  ReceivedQuantity: "Received quantity",
  ReceivedQuantity1: "Received quantity",
  PleaseSelectANetwork: "Please select a network",
  LengthLimit: "Length limit",
  Bytes: "Bytes",
  TheAddressIsIllegal: "The address does not exist/is illegal, please re-enter",

  SelecttransferCurrency: "Select transfer currency",
  SelectTransferCurrency: "Select Transfer Currency",

  OnlySupported: "Only supported",
  OnlineCollection: "Online collection",
  ewmInfo: `Ensure that the payment network you choose is consistent with the network you use on the withdrawal platform, otherwise assets will be lost`,
  copy: "copy",
  SavePicture: "Save Picture",
  SuccessfullyCopied: "Successfully copied!",
  SaveQRCode: "Save QR code",
  pwdErr: "Incorrect account password, please re-enter",
  noPwdInfo: "No account password has been set, unable to transfer out assets;",
  noPwdInfoTo: "Set Now",
  chainType: "chain type",
  setValuation: "pricing",
  PricingSettings: "Pricing Settings",
  LanguageSettings: "Language settings",
  mail: "mail",
  mobilePhone: "mobile phone",
  mobileAccountPassword: "mobile account password",

  NewAddressBook: "New Address Book",
  AddressBookManagement: "Address book management",
  address: "address",
  name: "name",
  Remarks: "Remarks (optional)",
  delete: "delete",
  // confirm: "confirm",
  // PleaseSelectANetwork: "Please select a network",
  contactInformation: "Contact information",
  Unbind: "Unbind",
  AccountSecurity: "Account security",
  //ChooseAWallet: 'Choose a wallet',
  Collection: "Collection",
  //Transfer: 'Transfer',
  whole: "All",
  Entry: "Entry",
  Outgoing: "Outgoing",
  //Exchange: 'Exchange',
  fail: "Failed",
  ToBeCompleted: "To be completed",
  Complete: "Complete",
  //Fail: 'Failed',
  TransferOutHandlingFee: "Transfer out handling fee",
  ExchangeEntry: "Exchange entry",
  ExchangeOutOfAccount: `Exchange out of account`,
  ExchangeHandlingFee: "Exchange handling fee",
  ApplicationCharging: "Application Charging",
  InitiationTime: "Initiation time",
  State: "State",
  TransactionNumber: "Transaction number",
  //Received Quantity: 'Received Quantity',
  OriginatingAddress: "Originating Address",
  TimeOfReceipt: "Time of receipt",
  WithdrawalExpenses: "Withdrawal expenses",
  CashInToAccount: "Cash in to account",
  TransactionType: ` Transaction type`,
  Withdrawal: `Withdrawal`,
  InProgress: `In progress`,
  Quantity: `Quantity`,
  ChainNetwork: `Chain network`,
  networkTimeout: `Network timeout`,
  noNetwork: `No network`,
  Email: `Email`,
  minAmountErr: `Less than the minimum operating limit`,
  exChange0tips:
    "Please enter the quantity of expenses required for redemption",
  exChange1tips: "Please enter the quantity to be redeemed",

  SelectExchangeCurrency: "Select Exchange Currency",
  // ExpectedReceipt: "Expected receipt",
  ConfirmRedemption: "Confirm redemption",
  ExchangeCurrencySelection: "Exchange currency selection",
  QuantityOfCurrencyExchanged: "Quantity of currency exchanged",
  QuantityOfCurrencyTransferredIn: "Quantity of currency transferred in",
  ExpectedReceipt: "Expected receipt",
  PleaseSelectTheCurrencyToBeRedeemed:
    "Please select the currency to be redeemed",
  PleaseSelectTheCurrencyToExchangeIn:
    "Please select the currency to exchange in",

  emailSetTitle:
    "Email verification adds protection to your transfer and wallet security",
  BindEmail: "Bind email",
  ChangeEmail: "Change email",
  pwdSetTitle: "Mobile account password adds security to your wallet",
  pwdSetTitleInfo1:
    "Please enter the mobile account password for mobile account verification",
  UnboundPrompt: "Unbound prompt",
  SetAccountPassword: "Set dynamic account password",
  ChangeAccountPassword: "Change the account password",
  ForgotTheAccountPassword: "Forgot the account password",
  UnboundContactInformation:
    "The current account is not bound with contact information. To ensure the security of your account, ",
  phoneSetTitle:
    "Mobile phone verification adds protection to your transfer and wallet security",
  BindMobilePhone: "Bind mobile phone",
  ChangeMobilePhone: "Change mobile phone",
  EmailVerification: "Email verification",
  ChangeToPhoneNumberVerification: "Change to phone number verification",
  PleaseEnterTheVerificationCode: "Please enter the verification code",
  PleaseEnterTheVerificationParts0: `Please enter the `,
  PleaseEnterTheVerificationParts1: ` verification code`,

  MobileVerification: "Mobile verification",
  ChangeToEmailVerification: "Change to email verification",
  EmailAddress: "Email address",
  PleaseEnterAnEmailAddress: "Please enter an email address",
  Submit: "Submit",
  PleaseFillInAgainToConfirm: "Please fill in again to confirm",
  ReturnInput: "Reset",
  PleaseEnterAValidPhoneNumber: "Please enter a valid phone number",
  PleaseEnterYourPhoneVerificationCode:
    "Please enter the phone verification code",
  ConfirmSettings: "Confirm settings",
  InconsistentPassword: "Inconsistent account password",
  EmailSuccessfullyBound: "The email has been successfully bound",
  TheNewEmailHasBeenSuccessfullyChanged:
    "The new email has been successfully changed",
  MobilePhoneSuccessfullyBound: "The mobile phone has been successfully bound",
  NewMobilePhoneHasBeenChanged:
    "The new mobile phone has been successfully changed",
  TheAccountPasswordHasBeenSuccessfullySet:
    "The account password has been successfully set",
  SuccessfullyChangedTheAccountPassword:
    "The account password was successfully changed",
  SendVerificationCode: "Send verification code",
  PleaseEnterSearchKeywords: "Please enter search keywords",
  SuccessfullyDeleted: "Successfully deleted",
  SuccessfullyAdded: "Successfully added",
  emailErr: "Email format error",
  emptyErr: "cannot be empty",
  SelCurrencyList: "Select currency list",
  // Collection: `Collection`,
  transferAccounts: `Transfer accounts`,
  SetUp: `set up`,
  NoPwdRechargeInfo:
    "No account password has been set, unable to initiate recharge;",
  NoPwdExchangeInfo:
    "No account password has been set, unable to initiate redemption;",
  addressCheck: `address check`,
  charging: `charging...`,
  InsufficientHandlingFees: `Insufficient handling fees`,
  PleaseEnter48Characters: `Please enter 48 characters`,
  LoadingFailed: `Loading failed`,
  ParsingFailed: `Parsing failed`,
  PleaseSelectAnAreaCode: "Select Country code",
  pleaseEnter: "Please Enter",
  numbers: "Digits",
  NextStep: `Next step`,
  passwordAuthentication: `password authentication`,
  ChangeTheAccountPassword: `Change the account password`,
  ChangeTheAccountPasswordInfo:
    "Enter the old dynamic account password to verify identity",
  delAddressMsg: "Are you sure you want to delete the current address?",
  PleaseEnter: "Please enter",
  PleaseSelect: "Please select",
  AddressModificationSuccessful: `Address modification successful`,
  CannotBeLessThan: "cannot be less than",
  CannotBeGreaterThan: "cannot be greater than",
  Byte: "Byte",
  RechargeToApplicationAccount: "Recharge to application account",
  不能向自己錢包轉帳: `Unable to transfer funds to one's own wallet`,
  ForgotPassword: "Forgot password",
  VerificationCodeError: `Verification code error`,
  PasswordError: `Password error`,
  minimumOutput: `minimum output`,
  share: "share",
  LongPressToSaveLocally: `Long press to save locally`,
  NoMoreThanDecimalSeparator: `No more than Decimal separator`,
  position: `position`,
  IntegerBitsCannotExceed: `Integer bits cannot exceed`,
  PleaseCorrect: `Please correct`,
  MustBeGreaterThan: `Must be greater than`,
  code0: "Sending failed",
  code1: "Sending unknown code error",
  code2: "SMS sending error",
  code3: "Verification failed",
  code4: "Verification code error",
  code5: "The verification code has expired",
  code6: "The sending frequency is too high, please try again later",
  LinkFailure: `Link failure`,
  code6part1: `The sending frequency is too high, please send it at`,
  code6part2: `try again in seconds`,
  // ExchangeHandlingFee: `Exchange handling fee`,
  code7: "Mobile account password error",
  inOperation: "In operation",
  InvalidPhoneNumber: `Invalid phone number`,
  paste: `paste`
};
