<template>
  <div class="text-over">
    <slot></slot>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.text-over{
  display: inline-block;
  word-wrap: break-word;
  word-break: break-all;
}
</style>