import request from "@/utils/request.js";

export function bindPhone(data) {
  return request({ method: "post", url: "/api/wallet/sms/bindPhone", data });
}

export function bindEmail(data) {
  return request({ method: "post", url: "/api/wallet/sms/bindEmail", data });
}

export function modifyPhoneVerifyNew(data) {
  return request({
    method: "post",
    url: "/api/wallet/sms/modifyPhoneVerifyNew",
    data,
  });
}

export function modifyPhoneVerifyOld(data) {
  return request({
    method: "post",
    url: "/api/wallet/sms/modifyPhoneVerifyOld",
    data,
  });
}

export function withdrawByPhone(data) {
  return request({
    method: "post",
    url: "/api/wallet/sms/withdrawByPhone",
    data,
  });
}

export function transferInByPhone(data) {
  return request({
    method: "post",
    url: "/api/wallet/sms/transferInByPhone",
    data,
  });
}

export function modifyEmailVerifyNew(data) {
  return request({
    method: "post",
    url: "/api/wallet/sms/modifyEmailVerifyNew",
    data,
  });
}

export function modifyEmailVerifyOld(data) {
  return request({
    method: "post",
    url: "/api/wallet/sms/modifyEmailVerifyOld",
    data,
  });
}

export function withdrawByEmail(data) {
  return request({
    method: "post",
    url: "/api/wallet/sms/withdrawByEmail",
    data,
  });
}

export function transferInByEmail(data) {
  return request({
    method: "post",
    url: "/api/wallet/sms/transferInByEmail",
    data,
  });
}

export function checkVerificationCode(data) {
  return request({
    method: "post",
    url: "/api/wallet/sms/checkVerificationCode",
    data,
  });
}

// 123
export function walletSendVerificationCode(data) {
  return request({
    method: "post",
    url: "/api/wallet/sendVerificationCode",
    data,
  });
}

export function walletCheckVerificationCode(data) {
  return request({
    method: "post",
    url: "/api/wallet/sms/checkVerificationCode",
    data,
  });
}

export function walletBindEmail(data) {
  return request({ method: "post", url: "/api/wallet/bindEmail", data });
}

export function walletBindPhone(data) {
  return request({ method: "post", url: "/api/wallet/bindPhone", data });
}

export function walletModifyEmail(data) {
  return request({ method: "post", url: "/api/wallet/modifyEmail", data });
}

export function walletModifyPhone(data) {
  return request({ method: "post", url: "/api/wallet/modifyPhone", data });
}

export function walletSetPassword(data) {
  return request({ method: "post", url: "/api/wallet/setPassword", data });
}
