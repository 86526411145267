<template>
  <div class="msg-title">
    <slot></slot>
    <div
      class="msg-title-bar"
      v-if="bar"
      :style="{
        'background-color': bar,
      }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    bar: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.msg-title {
  position: relative;
  font-size: 24px;
  font-weight: 600;
  color: #101010;
  line-height: 32px;
  text-align: center;
  padding-bottom: 9px;
  margin-bottom: 13px;
  margin-top: 6px;
  .msg-title-bar {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 28px;
    height: 3px;
    margin-left: -14px;
    display: block;
    border-radius: 2px;
  }
  &::after {
    display: none;
  }
}
</style>
