<template>
  <div class="app-main">
    <transition :name="curAni" :mode="mode">
      <keep-alive :include="cViews">
        <router-view :key="key" class="child-view"></router-view>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

const anis = [
  "fade-transform",
  "slide-right",
  "slide-left",
  "pre-slide-left",
  "pre-slide-right",
];

export default {
  data() {
    return {
      anis: anis,
      curAni: anis[0],
      mode: "",
      preHLength: window.history.length,
    };
  },
  computed: {
    ...mapGetters(["cViews"]),
    key() {
      // 解决同一个组件跳转不触发vue的created或者mounted钩子的问题
      return this.$route.path;
    },
  },
  created() {
    // 创建vm实例后执行
    // 浏览器控制按钮前进后退触发函数
    window.addEventListener("popstate", this.popstate, false);
  },
  destroyed() {
    // 销毁vm组件
    // 避免堆栈溢出，多次创建、多次触发
    window.removeEventListener("popstate", this.popstate, false);
  },
  methods: {
    popstate(e, e2) {
      console.log(window.history, "123123", e, e2);
      // const nowHLength = window.history.length;
      // if (nowHLength < this.preHLength) {
      //   this.isBack = true;
      // } else {
      //   this.isBack = false;
      // }
      // console.log("发生跳转", e);
    },
  },
  watch: {
    $route: {
      handler() {
        // const isBack = this.$router.isBack;
        // const toTab = to && to.meta && to.meta.tab;
        // const fromTab = from && from.meta && from.meta.tab;
        // this.mode = "";
        // if (toTab && fromTab) {
        this.mode = "out-in";
        this.curAni = anis[0];
        this.$router.isBack = "";
        //   return;
        // } else if (toTab) {
        //   this.curAni = anis[3];
        //   this.$router.isBack = "";
        //   return;
        // } else if (fromTab) {
        //   this.curAni = anis[4];
        //   this.$router.isBack = "";
        //   return;
        // }
        // this.curAni = isBack ? anis[1] : anis[2];
        // this.$router.isBack = "";
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  min-height: 100%;
  width: 100%;
  margin: auto;
  position: relative;
  overflow: hidden;
  background: #ffffff;
}
/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: translate3d opacity 0.5s;
}

.fade-transform-enter,
.pre-slide-left-enter,
.pre-slide-right-leave-active {
  opacity: 0;
  transform: translate3d(-30px, 0, 0);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translate3d(30px, 0, 0);
}

.child-view {
  position: absolute;
  width: 100%;
  transition: all 0.5s;
  height: 100%;
}
.slide-left-enter,
.slide-right-leave-active,
.pre-slide-left-leave-active,
.pre-slide-right-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active,
.slide-right-enter {
  opacity: 1;
  transform: translate3d(-100%, 0, 0);
}
</style>
