export default {
  200: "Everything is normal",
  400: "Missing or malformed parameters",
  401: "apiKey error",
  402: "Parameter format correct but request failed, business error",
  403: "The requested resource does not exist",
  500: "Server error",
  1000: "Database error",
  10010: "Database processing error",
  10020: "Server error",
  10030: "Request restricted",
  10040: "Unsupported operation",
  10050: "Parameter validation failed",
  10060: "Missing parameter",
  11000: "Unable to find the specified data",
  11010: "Unable to find user",
  11020: "Token not found",
  11030: "Unable to find merchant project",
  11040: "Unable to find wallet",
  12000: "Phone parameter error",
  12010: "Error in mailbox parameters",
  12020: "Mobile phone not bound",
  12030: "Unbound email",
  12040: "The phone has been bound",
  12050: "The email has been bound",
  12060: "The phone is occupied",
  12070: "The mailbox is occupied",
  12080: "Quantity must be greater than 0",
  12090: "The input quantity has not reached the minimum quantity",
  12100: "Insufficient balance",
  12110: "Recharge failed/abnormal, please contact customer service",
  12120: "Decimal place exceeds limit",
  11050: "Invalid wallet address",
};
