var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ListSelects',{ref:"alert",attrs:{"title":_vm.$t('PricingSettings'),"data":_vm.appCurrencys.map((item) => {
        return {
          name: `${_vm.$t(item.i18nName)}-${item.currency}`,
          val: item.key,
          data: item,
        };
      }),"default-val":_vm.appCurCurrency.key},on:{"change":_vm.select},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_vm._v(" "+_vm._s(`${item.data.currency}-${_vm.$t(item.data.i18nName)}`)+" ")]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }