const CryptoJS = require('crypto-js')

// 秘钥
var aesKey = '61f8dfa7354f950331d4b2221dc9b76f'

// 将秘钥转换成Utf8字节数组
var key = CryptoJS.enc.Utf8.parse(aesKey)

// 加密参数
const option = {
  iv: CryptoJS.enc.Utf8.parse(aesKey.substr(0, 16)),
  mode: CryptoJS.mode.ECB,
  padding: CryptoJS.pad.Pkcs7
}
// 加密
export const encrypt = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), key, option).toString()
}
// 解密
export const decrypt = (jmData) => {
  return JSON.parse(CryptoJS.AES.decrypt(jmData, key, option).toString(CryptoJS.enc.Utf8))
}
// function  encrypt(word){
//   var key  = CryptoJS.enc.Utf8.parse('0bc4fb11bd5663ab2e58cca88e04cf96');
//   var encrypted = CryptoJS.AES.encrypt(word, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7}).toString();
//   return encrypted;
// }
