export default [
  {
    "label": "阿富汗",
    "value": "+93",
    "length": "9"
  },
  {
    "label": "阿爾巴尼亞",
    "value": "+355",
    "length": "9"
  },
  {
    "label": "阿爾及利亞",
    "value": "+213",
    "length": "9"
  },
  {
    "label": "美屬薩摩亞",
    "value": "+1684",
    "length": "7"
  },
  {
    "label": "安道爾",
    "value": "+376",
    "length": "9"
  },
  {
    "label": "安哥拉",
    "value": "+244",
    "length": "9"
  },
  {
    "label": "安圭拉",
    "value": "+1264",
    "length": "7"
  },
  {
    "label": "安提瓜和巴布達",
    "value": "+1268",
    "length": "7"
  },
  {
    "label": "阿根廷",
    "value": "+54",
    "length": "10"
  },
  {
    "label": "亞美尼亞",
    "value": "+374",
    "length": "8"
  },
  {
    "label": "阿魯巴",
    "value": "+297",
    "length": "7"
  },
  {
    "label": "澳大利亞",
    "value": "+61",
    "length": "9"
  },
  {
    "label": "奧地利",
    "value": "+43",
    "length": "10"
  },
  {
    "label": "阿塞拜疆",
    "value": "+994",
    "length": "9"
  },
  {
    "label": "巴哈馬",
    "value": "+1242",
    "length": "7"
  },
  {
    "label": "巴林",
    "value": "+973",
    "length": "8"
  },
  {
    "label": "孟加拉國",
    "value": "+880",
    "length": "8"
  },
  {
    "label": "巴巴多斯",
    "value": "+1246",
    "length": "7"
  },
  {
    "label": "白俄羅斯",
    "value": "+375",
    "length": "9"
  },
  {
    "label": "比利時",
    "value": "+32",
    "length": "9"
  },
  {
    "label": "伯利茲",
    "value": "+501",
    "length": "7"
  },
  {
    "label": "貝寧",
    "value": "+229",
    "length": "8"
  },
  {
    "label": "百慕大羣島",
    "value": "+1441",
    "length": "7"
  },
  {
    "label": "不丹",
    "value": "+975",
    "length": "8"
  },
  {
    "label": "玻利維亞",
    "value": "+591",
    "length": "8"
  },
  {
    "label": "波斯尼亞和黑塞哥維那",
    "value": "+387",
    "length": "8|9"
  },
  {
    "label": "博茨瓦納",
    "value": "+267",
    "length": "8"
  },
  {
    "label": "巴西",
    "value": "+55",
    "length": "9"
  },
  {
    "label": "文萊",
    "value": "+673",
    "length": "7"
  },
  {
    "label": "保加利亞",
    "value": "+359",
    "length": "9"
  },
  {
    "label": "布基納法索",
    "value": "+226",
    "length": "8"
  },
  {
    "label": "布隆迪",
    "value": "+257",
    "length": "8"
  },
  {
    "label": "柬埔寨",
    "value": "+855",
    "length": "8|9"
  },
  {
    "label": "喀麥隆",
    "value": "+237",
    "length": "9"
  },
  {
    "label": "加拿大",
    "value": "+1",
    "length": "10",
    key:'Canada'
  },
  {
    "label": "佛得角",
    "value": "+238",
    "length": "7"
  },
  {
    "label": "開曼羣島",
    "value": "+1345",
    "length": "7"
  },
  {
    "label": "中非共和國",
    "value": "+236",
    "length": "8"
  },
  {
    "label": "乍得",
    "value": "+235",
    "length": "8"
  },
  {
    "label": "智利",
    "value": "+56",
    "length": "9"
  },
  {
    "label": "中國大陸",
    "value": "+86",
    "length": "11"
  },
  {
    "label": "哥倫比亞",
    "value": "+57",
    "length": "10"
  },
  {
    "label": "科摩羅",
    "value": "+269",
    "length": "7"
  },
  {
    "label": "庫克羣島",
    "value": "+682",
    "length": "5"
  },
  {
    "label": "哥斯達黎加",
    "value": "+506",
    "length": "8"
  },
  {
    "label": "科特迪瓦",
    "value": "+225",
    "length": "8"
  },
  {
    "label": "克羅地亞",
    "value": "+385",
    "length": "9"
  },
  {
    "label": "庫拉索",
    "value": "+599",
    "length": "7"
  },
  {
    "label": "塞浦路斯",
    "value": "+357",
    "length": "8"
  },
  {
    "label": "捷克",
    "value": "+420",
    "length": "9"
  },
  {
    "label": "剛果民主共和國",
    "value": "+243",
    "length": "9"
  },
  {
    "label": "丹麥",
    "value": "+45",
    "length": "8"
  },
  {
    "label": "吉布提",
    "value": "+253",
    "length": "8"
  },
  {
    "label": "多米尼克",
    "value": "+1767",
    "length": "7"
  },
  {
    "label": "多米尼加共和國",
    "value": "+1809",
    "length": "7"
  },
  {
    "label": "厄瓜多爾",
    "value": "+593",
    "length": "7"
  },
  {
    "label": "埃及",
    "value": "+20",
    "length": "10"
  },
  {
    "label": "薩爾瓦多",
    "value": "+503",
    "length": "8"
  },
  {
    "label": "赤道幾內亞",
    "value": "+240",
    "length": "9"
  },
  {
    "label": "厄立特里亞",
    "value": "+291",
    "length": "7"
  },
  {
    "label": "愛沙尼亞",
    "value": "+372",
    "length": "7|8"
  },
  {
    "label": "斯威士蘭",
    "value": "+268",
    "length": "8"
  },
  {
    "label": "埃塞俄比亞",
    "value": "+251",
    "length": "9"
  },
  {
    "label": "法羅羣島",
    "value": "+298",
    "length": "6"
  },
  {
    "label": "斐濟",
    "value": "+679",
    "length": "7"
  },
  {
    "label": "芬蘭",
    "value": "+358",
    "length": "9"
  },
  {
    "label": "法國",
    "value": "+33",
    "length": "9"
  },
  {

    "label": "法屬圭亞那",
    "value": "+594",
    "length": "9"
  },
  {
    "label": "法屬波利尼西亞",
    "value": "+689",
    "length": "8"
  },
  {
    "label": "加蓬",
    "value": "+241",
    "length": "8"
  },
  {
    "label": "岡比亞",
    "value": "+220",
    "length": "7"
  },
  {
    "label": "格魯吉亞",
    "value": "+995",
    "length": "9"
  },
  {
    "label": "德國",
    "value": "+49",
    "length": "11"
  },
    
  {
    "label": "加納",
    "value": "+233",
    "length": "9"
  },
  {
    "label": "直布羅陀",
    "value": "+350",
    "length": "8"
  },
  {
    "label": "希臘",
    "value": "+30",
    "length": "10"
  },
  {
    "label": "格陵蘭島",
    "value": "+299",
    "length": "6"
  },
  {
    "label": "格林納達",
    "value": "+1473",
    "length": "7"
  },
  {
    "label": "瓜德羅普島",
    "value": "+590",
    "length": "9"
  },
  {
    "label": "關島",
    "value": "+1671",
    "length": "7"
  },
  {
    "label": "危地馬拉",
    "value": "+502",
    "length": "8"
  },
  {
    "label": "幾內亞",
    "value": "+224",
    "length": "9"
  },
  {
    "label": "幾內亞比紹共和國",
    "value": "+245",
    "length": "9"
  },
  {
    "label": "圭亞那",
    "value": "+592",
    "length": "7"
  },
  {
    "label": "海地",
    "value": "+509",
    "length": "8"
  },
  {
    "label": "洪都拉斯",
    "value": "+504",
    "length": "8"
  },
  {
    "label": "中國香港",
    "value": "+852",
    "length": "8"
  },
  {
    "label": "匈牙利",
    "value": "+36",
    "length": "9"
  },
  {
    "label": "冰島",
    "value": "+354",
    "length": "7"
  },
  {
    "label": "印度",
    "value": "+91",
    "length": "10"
  },
  {
    "label": "印度尼西亞",
    "value": "+62",
    "length": "10|11"
  },
  {
    "label": "伊拉克",
    "value": "+964",
    "length": "10"
  },
  {
    "label": "愛爾蘭",
    "value": "+353",
    "length": "9"
  },
  {
    "label": "以色列",
    "value": "+972",
    "length": "9"
  },
  {
    "label": "意大利",
    "value": "+39",
    "length": "10"
  },
  {
    "label": "牙買加",
    "value": "+1876",
    "length": "7"
  },
  {
    "label": "日本",
    "value": "+81",
    "length": "10"
  },
  {
    "label": "約旦",
    "value": "+962",
    "length": "9"
  },
  {
    "label": "哈薩克斯坦",
    "value": "+7",
    "length": "10"
  },
  {
    "label": "肯尼亞",
    "value": "+254",
    "length": "9"
  },
  {
    "label": "基里巴斯",
    "value": "+686",
    "length": "7"
  },
  {
    "label": "科威特",
    "value": "+965",
    "length": "8"
  },
  {
    "label": "吉爾吉斯斯坦",
    "value": "+996",
    "length": "9"
  },
  {
    "label": "老撾",
    "value": "+856",
    "length": "10"
  },
  {
    "label": "拉脫維亞",
    "value": "+371",
    "length": "8"
  },
  {
    "label": "黎巴嫩",
    "value": "+961",
    "length": "8"
  },
  {
    "label": "萊索托",
    "value": "+266",
    "length": "8"
  },
  {
    "label": "利比里亞",
    "value": "+231",
    "length": "9"
  },
  {
    "label": "利比亞",
    "value": "+218",
    "length": "8"
  },
  {
    "label": "列支敦士登",
    "value": "+423",
    "length": "7"
  },
  {
    "label": "立陶宛",
    "value": "+370",
    "length": "8"
  },
  {
    "label": "盧森堡",
    "value": "+352",
    "length": "9"
  },
  {
    "label": "中國澳門",
    "value": "+853",
    "length": "8"
  },
  {
    "label": "北馬其頓",
    "value": "+389",
    "length": "8"
  },
  {
    "label": "馬達加斯加",
    "value": "+261",
    "length": "9"
  },
  {
    "label": "馬拉維",
    "value": "+265",
    "length": "9"
  },
  {
    "label": "馬來西亞",
    "value": "+60",
    "length": "9|10"
  },
  {
    "label": "馬爾代夫",
    "value": "+960",
    "length": "7"
  },
  {
    "label": "馬裏",
    "value": "+223",
    "length": "8"
  },
  {
    "label": "馬耳他",
    "value": "+356",
    "length": "8"
  },
  {
    "label": "馬提尼克",
    "value": "+596",
    "length": "9"
  },
  {
    "label": "毛里塔尼亞",
    "value": "+222",
    "length": "8"
  },
  {
    "label": "毛里求斯",
    "value": "+230",
    "length": "8"
  },
  {
    "label": "馬約特",
    "value": "+262",
    "length": "9",
    key: 'Mayotte'
  },
  {
    "label": "墨西哥",
    "value": "+52",
    "length": "10"
  },
  {
    "label": "摩爾多瓦",
    "value": "+373",
    "length": "8"
  },
  {
    "label": "摩納哥",
    "value": "+377",
    "length": "8"
  },
  {
    "label": "蒙古",
    "value": "+976",
    "length": "8"
  },
  {
    "label": "黑山",
    "value": "+382",
    "length": "8"
  },
  {
    "label": "蒙特塞拉特島",
    "value": "+1664",
    "length": "7"
  },
  {
    "label": "蒙特塞拉特島",
    "value": "+1664",
    "length": "7"
  },
  {
    "label": "摩洛哥",
    "value": "+212",
    "length": "9"
  },
  {
    "label": "莫桑比克",
    "value": "+258",
    "length": "9"
  },
  {
    "label": "緬甸",
    "value": "+95",
    "length": "10"
  },
  {
    "label": "納米比亞",
    "value": "+264",
    "length": "9"
  },
  {
    "label": "尼泊爾",
    "value": "+977",
    "length": "10"
  },
  {
    "label": "荷蘭",
    "value": "+31",
    "length": "9"
  },
  {
    "label": "新喀里多尼亞",
    "value": "+687",
    "length": "6"
  },
  {
    "label": "新西蘭",
    "value": "+64",
    "length": "8|9|10"
  },
  {
    "label": "尼加拉瓜",
    "value": "+505",
    "length": "8"
  },
  {
    "label": "尼日爾",
    "value": "+227",
    "length": "8"
  },
  {
    "label": "尼日利亞",
    "value": "+234",
    "length": "10"
  },
  {
    "label": "挪威",
    "value": "+47",
    "length": "8"
  },
  {
    "label": "阿曼",
    "value": "+968",
    "length": "8"
  },
  {
    "label": "巴基斯坦",
    "value": "+92",
    "length": "10"
  },
  {
    "label": "帕勞",
    "value": "+680",
    "length": "7"
  },
  {
    "label": "巴勒斯坦",
    "value": "+970",
    "length": "9"
  },
  {
    "label": "巴拿馬",
    "value": "+507",
    "length": "8"
  },
  {
    "label": "巴布亞新幾內亞",
    "value": "+675",
    "length": "8"
  },
  {
    "label": "巴拉圭",
    "value": "+595",
    "length": "9"
  },
  {
    "label": "祕魯",
    "value": "+51",
    "length": "9"
  },
  {
    "label": "菲律賓",
    "value": "+63",
    "length": "10"
  },
  {
    "label": "波蘭",
    "value": "+48",
    "length": "9"
  },
  {
    "label": "葡萄牙",
    "value": "+351",
    "length": "9"
  },
  {
    "label": "波多黎各",
    "value": "+1787",
    "length": "7"
  },
  {
    "label": "卡塔爾",
    "value": "+974",
    "length": "8"
  },
  {
    "label": "剛果共和國",
    "value": "+242",
    "length": "9"
  },
  {
    "label": "留尼汪",
    "value": "+262",
    "length": "9",
    key:"Reunion"
  },
  {
    "label": "羅馬尼亞",
    "value": "+40",
    "length": "9"
  },
  {
    "label": "盧旺達",
    "value": "+250",
    "length": "9"
  },
  {
    "label": "聖基茨和尼維斯",
    "value": "+1869",
    "length": "7"
  },
  {
    "label": "聖露西亞",
    "value": "+1758",
    "length": "7"
  },
  {
    "label": "聖彼埃爾和密克隆島",
    "value": "+508",
    "length": "6"
  },
  {
    "label": "聖文森特和格林納丁斯",
    "value": "+1784",
    "length": "7"
  },
  {
    "label": "薩摩亞",
    "value": "+685",
    "length": "7"
  },
  {
    "label": "聖馬力諾",
    "value": "+378",
    "length": "8"
  },
  {
    "label": "聖多美和普林西比",
    "value": "+239",
    "length": "7"
  },
  {
    "label": "沙特阿拉伯",
    "value": "+966",
    "length": "9"
  },
  {
    "label": "塞內加爾",
    "value": "+221",
    "length": "7"
  },
  {
    "label": "塞爾維亞",
    "value": "+381",
    "length": "9"
  },
  {
    "label": "塞舌爾",
    "value": "+248",
    "length": "7"
  },
  {
    "label": "塞拉利昂",
    "value": "+232",
    "length": "8"
  },
  {
    "label": "新加坡",
    "value": "+65",
    "length": "8"
  },
  {
    "label": "聖馬丁島（荷蘭部分）",
    "value": "+1721",
    "length": "7"
  },
  {
    "label": "斯洛伐克",
    "value": "+421",
    "length": "9"
  },
  {
    "label": "斯洛文尼亞",
    "value": "+386",
    "length": "8"
  },
  {
    "label": "所羅門羣島",
    "value": "+677",
    "length": "7"
  },
  {
    "label": "索馬里",
    "value": "+252",
    "length": "9"
  },
  {
    "label": "南非",
    "value": "+27",
    "length": "9"
  },
  {
    "label": "韓國",
    "value": "+82",
    "length": "10"
  },
  {
    "label": "西班牙",
    "value": "+34",
    "length": "9"
  },
  {
    "label": "斯里蘭卡",
    "value": "+94",
    "length": "9"
  },
  {
    "label": "蘇里南",
    "value": "+597",
    "length": "7"
  },
  {
    "label": "瑞典",
    "value": "+46",
    "length": "9"
  },
  {
    "label": "瑞士",
    "value": "+41",
    "length": "9"
  },
  {
    "label": "中國臺灣",
    "value": "+886",
    "length": "9"
  },
  {
    "label": "塔吉克斯坦",
    "value": "+992",
    "length": "9"
  },
  {
    "label": "坦桑尼亞",
    "value": "+255",
    "length": "9"
  },
  {
    "label": "泰國",
    "value": "+66",
    "length": "9"
  },
  {
    "label": "東帝汶",
    "value": "+670",
    "length": "8"
  },
  {
    "label": "多哥",
    "value": "+228",
    "length": "8"
  },
  {
    "label": "湯加",
    "value": "+676",
    "length": "7"
  },
  {
    "label": "特立尼達和多巴哥",
    "value": "+1868",
    "length": "7"
  },
  {
    "label": "突尼斯",
    "value": "+216",
    "length": "8"
  },
  {
    "label": "土耳其",
    "value": "+90",
    "length": "10"
  },
  {
    "label": "土庫曼斯坦",
    "value": "+993",
    "length": "8"
  },
  {
    "label": "特克斯和凱科斯羣島",
    "value": "+1649",
    "length": "7"
  },
  {
    "label": "烏干達",
    "value": "+256",
    "length": "9"
  },
  {
    "label": "烏克蘭",
    "value": "+380",
    "length": "9"
  },
  {
    "label": "阿拉伯聯合酋長國",
    "value": "+971",
    "length": "9"
  },
  {
    "label": "英國",
    "value": "+44",
    "length": "10"
  },
  {
    "label": "美國",
    "value": "+1",
    "length": "10",
    key: 'UnitedStates'
  },
  {
    "label": "烏拉圭",
    "value": "+598",
    "length": "8"
  },
  {
    "label": "烏茲別克斯坦",
    "value": "+998",
    "length": "9"
  },
  {
    "label": "瓦努阿圖",
    "value": "+678",
    "length": "7"
  },
  {
    "label": "委內瑞拉",
    "value": "+58",
    "length": "10"
  },
  {
    "label": "越南",
    "value": "+84",
    "length": "9"
  },
  {
    "label": "英屬維爾京羣島",
    "value": "+1284",
    "length": "7"
  },
  {
    "label": "美屬維爾京羣島",
    "value": "+1340",
    "length": "7"
  },
  {
    "label": "也門",
    "value": "+967",
    "length": "9"
  },
  {
    "label": "贊比亞",
    "value": "+260",
    "length": "9"
  },
  {
    "label": "津巴布韋",
    "value": "+263",
    "length": "9"
  }
]
