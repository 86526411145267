<template>
  <img
    v-if="src"
    :class="{
      round: round,
    }"
    class="base-img"
    v-lazy="src"
  />
  <div
    v-else
    class="base-img base-img-empty"
    :class="{
      round: round,
    }"
  ></div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: "",
    },
    round: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.base-img {
  display: inline-block;
  vertical-align: middle;
  &.base-img-empty {
    background: rgb(226, 226, 226);
  }
  &.round {
    border-radius: 50%;
  }
}
</style>
