<template>
  <div class="selects-item">
    <div class="selects-item-cont">
      <slot></slot>
    </div>
    <div class="selects-item-aperate">
      <svg-icon iconClass="sel" v-if="active"></svg-icon>
      <svg-icon iconClass="no-sel" v-else></svg-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.selects-item {
  display: flex;
  align-items: center;
  .selects-item-cont {
    flex: 1;
  }
  .selects-item-aperate {
    flex-shrink: 0;
    .svg-icon {
      width: 24px;
      height: 24px;
      font-size: 24px;
    }
  }
}
</style>
