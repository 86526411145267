import "amfe-flexible";
import Vue from "vue";
import "@/styles/index.scss";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n, Locals } from "@/langs";
import $to from "@/utils/$to";
import $NP from "@/utils/NP";
import { $rule } from "@/utils/validate.js";
import $day from "dayjs";
import Cookies from "js-cookie";
// 权限控制
import "@/permission";
// 图标
import "./icons";
import * as filters from "./filters"; // global filters
// 注册全局过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
import "@/components/importVantParts";
import BasePage from "@/components/BasePage";
import SelLang from "@/components/SelLang";
import SetValuation from "@/components/setValuation";
import BScroll from "@/components/BScroll/index";
import BaseList from "@/components/BaseList/index";
import BaseImg from "@/components/BaseImg";
import TextHms from "@/components/TextHms";
import CurrencyItem from "@/components/CurrencyItem";
import clipboard from "@/directive/clipboard/index.js";
import BaseAlert from "@/components/BaseAlert";
import ValidMethods from "@/components/ValidMethods";
import TextOver from "@/components/TextOver";
import PhoneAreaSelect from "@/components/PhoneAreaSelect";
import MsgTitle from "@/components/MsgTitle";
import SelectsItem from "@/components/SelectsItem";
import ListSelects from "@/components/ListSelects";
import { BitNum, Loading } from "@/utils/validate";
import ForgotPassword from "@/components/ForgotPassword";
import TextAutoSize from "@/components/TextAutoSize";
Vue.component("TextAutoSize", TextAutoSize);
Vue.component("ForgotPassword", ForgotPassword);
Vue.component("ListSelects", ListSelects);
Vue.component("SelectsItem", SelectsItem);
Vue.component("MsgTitle", MsgTitle);
Vue.component("PhoneAreaSelect", PhoneAreaSelect);
Vue.component("TextOver", TextOver);
Vue.component("ValidMethods", ValidMethods);
Vue.component("BaseAlert", BaseAlert);
Vue.use(clipboard);
Vue.component("CurrencyItem", CurrencyItem);
Vue.component("TextHms", TextHms);
Vue.component("BaseImg", BaseImg);
Vue.component("BaseList", BaseList);
Vue.component("BScroll", BScroll);
Vue.component("BasePage", BasePage);
Vue.component("SelLang", SelLang);
Vue.component("SetValuation", SetValuation);

// if (process.env.NODE_ENV === "production" && process.env.MOCK) {
//   const { mockXHR } = require("../mock");
//   mockXHR();
// }
Vue.prototype.$token = (keyName) => {
  return Cookies.get(keyName) && JSON.parse(Cookies.get(keyName));
};
Vue.prototype.$alert = (data) => {
  store.dispatch("app/setAlert", data);
};
Vue.prototype.$Bit = BitNum;
Vue.prototype.$NP = $NP;
Vue.prototype.$day = $day;
Vue.prototype.$locals = Locals;
Vue.prototype.$to = $to;
Vue.prototype.$rule = $rule;
Vue.prototype.$loading = Loading;
Vue.prototype.$empty = (val, fn, fillFn) => {
  const isEmpty = [undefined, null, ""].includes(val);
  if (fn) {
    return isEmpty ? (fillFn ? fillFn() : "") : fn(val);
  }
  return isEmpty;
};
Vue.prototype.$iNum = (v) => {
  v = v.replace(/[^\d.]/g, ""); // 先把非数字的都替换掉，除了数字和.
  v = v.replace(/^\./g, ""); // 必须保证第一个为数字而不是.
  v = v.replace(/\.{2,}/g, "."); // 保证只有出现一个.而没有多个.
  v = v.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); // 保证.只出现一次，而不能出现两次以上
  return v;
};
Vue.prototype.$toNum = function getFullNum(num) {
  // 处理非数字
  if (isNaN(num)) {
    return num;
  }
  // 处理不需要转换的数字
  var str = "" + num;
  if (!/e/i.test(str)) {
    return num;
  }
  return num.toFixed(18).replace(/\.?0+$/, "");
};

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router,
  store,
  i18n,
}).$mount("#app");
