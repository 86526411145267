/* eslint-disable */
export default {
  ModuleLoading: "加載中...",
  ModuleLoadingFailed: "加載失敗",
  Reload: "重新加載",
  return: "返回",

  RefreshSuccessful: `刷新成功`,
  noData: `無數據`,
  close: `關閉`,
  AssetDetails: `資產詳情`,
  ViewAssetDetails: `查看資產詳情`,
  wallet: `錢包`,
  langTitle: `簡體中文`,
  myWallet: `我的錢包`,
  set: `設置`,
  securitySetting: `安全設置`,
  valuation: `計價`,
  addressBook: `地址本`,
  language: `語言`,
  USD: `美金`,
  NTD: `台幣`,
  setLangs: `語言設置`,
  setValuations: `計價設置`,
  conversionOfWalletAssets: `錢包資產折合`,
  cancel: `取消`,

  ChooseAWallet: `選擇錢包`,
  UnboundInfo: `當前帳戶未綁定聯繫方式，為了保障你帳戶安全去綁定`,
  Recharge: `充值`,
  TransferOut: `轉出`,
  TransferIn: `轉入`,
  exchange: `兌換`,
  asset: `資產`,
  ThereSNoMore: `沒有更多了`,
  Loading: `加載中`,
  NoData: `暫無數據`,
  RequestFailedClickToReload: `請求失敗，點擊重新加載`,
  SelectRechargeCurrency: `選擇充值幣種`,
  Currency: `幣種`,

  RechargePrompt: `充值提示`,
  RechargeCurrency: `充值幣種`,
  RechargeQuantity: `充值數量`,
  available: `可用`,
  prompt: `提示`,
  RechargePrompt1: `1、為了加強您的資金安全，輸入密碼時遠離旁人`,
  RechargePrompt2: `2、請仔細選擇您的到帳網路，否則到帳資產不可找回`,
  RechargePrompt3: `3、請務必確認帳戶安全，防止資訊被篡改或洩漏`,

  RechargeTo: `充值到`,
  PleaseSelectACurrency: `請選擇幣種`,
  PleaseEnterTheQuantityThatNeedsToBeRecharged: `請輸入需要充值的數量`,
  InsufficientAvailableBalance: `可用餘額不足`,
  ConfirmTransfer: `確認轉帳`,
  ToAccountNetwork: `到帳網路`,
  ArrivalAddress: `到帳地址`,
  TransferQuantity: `轉帳數量`,
  HandlingFees: `手續費`,
  ConfirmRecharge: `確認充值`,
  SecurityVerification: `安全驗證`,
  MobileAccountPassword: `動帳密碼`,
  Send: `發送`,
  SendInfo1: `確保您的收款網路是`,
  SendInfo2: `否則資產將會丟失`,

  PleaseEnterTheDeliveryAddress: `請輸入到帳地址`,
  PleaseEnterTheCorrectDeliveryAddress: `請輸入正確到帳地址`,
  ExceededYourCurrentBalance: `已超出您當前餘額`,
  NotSetSetUpNow: `未設置動帳密碼，無法轉出資產;`,
  PleaseEnterTheTransferAmount: `請輸入轉帳金額！`,
  PleaseEnterTheTransferAddress: `請輸入轉帳地址！`,

  network: `網路`,
  TransferAmount: `轉帳數額`,
  MinimumTransferAmount: `最小轉帳數額`,
  NetworkHandlingFees: `網路手續費`,
  Transfer: `轉帳`,
  confirm: `確認`,
  ReceivedQuantity: `到帳數量`,
  PleaseSelectANetwork: `請選擇網路`,
  LengthLimit: `長度限制`,
  Bytes: `個位元組`,
  TheAddressIsIllegal: `地址不存在/地址非法，重新輸入`,

  SelecttransferCurrency: `選擇轉帳幣種`,
  SelectTransferCurrency: `選擇轉入幣種`,

  OnlySupported: `僅支持`,
  OnlineCollection: `網路收款`,
  ewmInfo: `確保您選擇的收款網路與您在提幣平臺使用網路一致，否則資產將會丟失`,
  copy: `複製`,
  SavePicture: `保存圖片`,
  SuccessfullyCopied: `複製成功！`,
  SaveQRCode: `保存二維碼`,
  pwdErr: `動帳密碼錯誤，請重新輸入`,

  noPwdInfo: `未設置動帳密碼，無法轉出資產;`,
  noPwdInfoTo: `立即設置`,
  chainType: `鏈類型`,
  setValuation: `計價`,
  PricingSettings: `計價設置`,
  LanguageSettings: `語言設置`,
  mail: `郵件`,
  mobilePhone: `移動電話`,
  mobileAccountPassword: `動帳密碼`,
  NewAddressBook: `新建地址本`,
  AddressBookManagement: `地址本管理`,
  address: `地址`,
  name: `名稱`,
  Remarks: `備註(選填)`,
  delete: `刪除`, // confirm: `確認`, // PleaseSelectANetwork: `請選擇網路`,
  contactInformation: `聯繫方式`,
  Unbind: `去綁定`,
  AccountSecurity: `帳戶安全`, // ChooseAWallet: `選擇錢包`,
  Collection: `收款`, // Transfer: `轉帳`,
  whole: `全部`,
  Entry: `入帳`,
  Outgoing: `出帳`, // exchange: `兌換`,
  fail: `失敗`,
  ToBeCompleted: `待完成`,
  Complete: `完成`, // Fail: `失敗`,
  TransferOutHandlingFee: `轉出手續費`,
  ExchangeEntry: `兌換入帳`,
  ExchangeHandlingFee: `兌換手續費`,
  ApplicationCharging: `應用充提`,
  InitiationTime: `發起時間`,
  State: `狀態`,
  TransactionNumber: `交易號`,
  ReceivedQuantity1: `收款數量`,
  OriginatingAddress: `發起地址`,
  TimeOfReceipt: `到帳時間`,
  WithdrawalExpenses: `兌出支出`,
  CashInToAccount: `兌入到帳`,
  ExchangeOutOfAccount: `兌換出帳`,
  TransactionType: `交易類型`,
  Withdrawal: `提現`,
  InProgress: `進行中`,
  Quantity: `數量`,
  ChainNetwork: `鏈網路`,
  networkTimeout: `網路超時`,
  noNetwork: `無網路`,
  Email: `郵箱`,
  minAmountErr: `少於最小操作額度`,
  exChange0tips: `請輸入兌換所需支出的數量`,
  exChange1tips: `請輸入需要兌換的數量`,

  SelectExchangeCurrency: "選擇兌換幣種",
  ExpectedReceipt: "預計到帳",
  ConfirmRedemption: "確認兌換",
  ExchangeCurrencySelection: "兌出幣種選擇",
  QuantityOfCurrencyExchanged: "兌出幣種數量",
  QuantityOfCurrencyTransferred: "兌入幣種數量", // ExpectedReceipt: "預計到帳",
  PleaseSelectTheCurrencyToBeRedeemed: "請選擇兌換出的幣種",
  PleaseSelectTheCurrencyToExchangeIn: "請選擇要換入的幣種", // ===

  emailSetTitle: `郵箱驗證，為您的轉帳和錢包安全增加保護`,
  BindEmail: `綁定郵箱`,
  ChangeEmail: `更改郵箱`,
  pwdSetTitle: `動帳密碼，為您的錢包安全增加保護`,
  pwdSetTitleInfo1: `請輸入動帳密碼，用於動帳驗證`,
  UnboundPrompt: `未綁定提示`,
  SetAccountPassword: `設置動帳密碼`,
  ChangeAccountPassword: `更改動帳密碼`,
  ForgotTheAccountPassword: `忘記動帳密碼`,
  UnboundContactInformation: `當前帳戶未綁定聯繫方式，為了保障你帳戶安全`,
  phoneSetTitle: `移動電話驗證，為您的轉帳和錢包安全增加保護`,
  BindMobilePhone: `綁定移動電話`,
  ChangeMobilePhone: `更改移動電話`,
  EmailVerification: `郵箱驗證`,
  ChangeToPhoneNumberVerification: `換成手機號驗證`,
  PleaseEnterTheVerificationCode: `請輸入驗證碼`,
  PleaseEnterTheVerificationParts0: `請輸入`,
  PleaseEnterTheVerificationParts1: `驗證碼`,

  MobileVerification: `手機驗證`,
  ChangeToEmailVerification: `換成郵箱驗證`,
  EmailAddress: `郵箱地址`,
  PleaseEnterAnEmailAddress: `請輸入郵箱地址`,
  submit: `提交`,
  PleaseFillInAgainToConfirm: `請再次填寫以確認`,
  ReturnInput: `重新設置`,
  PleaseEnterAValidPhoneNumber: `請輸入有效手機號`,
  PleaseEnterYourPhoneVerificationCode: `請輸入手機驗證碼`,
  ConfirmSettings: `確認設置`,
  InconsistentPassword: `動帳密碼不一致`,
  EmailSuccessfullyBound: `郵箱已綁定成功`,
  TheNewEmailHasBeenSuccessfullyChanged: `新郵箱已更改成功`,
  MobilePhoneSuccessfullyBound: `移動電話已綁定成功`,
  NewMobilePhoneHasBeenChanged: `新移動電話已更改成功`,
  TheAccountPasswordHasBeenSuccessfullySet: `動帳密碼已設置成功`,
  SuccessfullyChangedTheAccountPassword: `動帳密碼更改成功`,
  SendVerificationCode: `發送驗證碼`,
  PleaseEnterSearchKeywords: `請輸入搜索關鍵字`,
  SuccessfullyDeleted: `刪除成功`,
  SuccessfullyAdded: `添加成功`,
  emailErr: `郵箱格式錯誤`,
  emptyErr: `不可為空`,
  SelCurrencyList: `選擇幣種列表`, // Collection: `收款`,
  transferAccounts: `轉帳`,
  SetUp: `設置`,
  NoPwdRechargeInfo: `未設置動賬密碼，無法發起充值;`,
  NoPwdExchangeInfo: `未設置動賬密碼，無法發起兌換;`,
  addressCheck: `地址校驗`,
  charging: `計費中...`,
  InsufficientHandlingFees: `手續費不足`,
  PleaseEnter48Characters: `請輸入48個字元`,
  LoadingFailed: `加載失敗`,
  ParsingFailed: `解析失敗`,
  pleaseSelectAnAreaCode: "請選擇區號",
  pleaseEnter: "請輸入",
  numbers: "個數字",
  NextStep: `下一步`,
  passwordAuthentication: `密码验证`,
  ChangeTheAccountPassword: `修改動賬密碼`,
  ChangeTheAccountPasswordInfo: `輸入舊動賬密碼, 以驗證身份`,
  delAddressMsg: `確認是否刪除當前地址？`,
  PleaseEnter: `請輸入 `,
  PleaseSelect: `請選擇`,
  AddressModificationSuccessful: `地址修改成功`,
  CannotBeLessThan: `不可小於`,
  CannotBeGreaterThan: `不可大於`,
  Byte: `位元組`,
  RechargeToApplicationAccount: `充值到應用帳戶`,
  不能向自己錢包轉帳: "不能向自己錢包轉帳",
  ForgotPassword: "忘記密碼",
  VerificationCodeError: `驗證碼錯誤`,
  PasswordError: `密碼錯誤`,
  share: "分享",
  LongPressToSaveLocally: `長按保存到本地`,
  NoMoreThanDecimalSeparator: `不可超過小數點`,
  position: `位`,
  IntegerBitsCannotExceed: `整數位不可超過`,
  PleaseCorrect: `請修正`,
  MustBeGreaterThan: `必須大於`,
  code0: `發送失敗`,
  code1: `发送未知代码错误`,
  code2: `短信发送错误`,
  code3: `驗證失敗`,
  code4: `驗證碼錯誤`,
  code5: `驗證碼已過期`,
  code6: `發送頻率太高, 請在稍候再試`,
  LinkFailure: `鏈接失效`,
  code6part1: `發送頻率太高, 請在`,
  code6part2: `秒之後再試試`,
  // ExchangeHandlingFee: `兌換手續費`,
  code7: `動賬密碼錯誤`,
  inOperation: "操作中",
  InvalidPhoneNumber: `手機號碼無效`,
  paste: `粘貼`
};
