var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"valid-methods",class:{
    'valid-methods-hide': _vm.hide,
  }},[_c('div',[(_vm.time < 1)?_c('div',[(!_vm.textBtn)?_c('van-button',{staticClass:"valid-methods-send-btn",attrs:{"size":"small","type":"info"},on:{"click":() => {
            _vm.getYzm();
          }}},[(_vm.loading)?_c('van-loading',{staticStyle:{"display":"inline-block"}}):_c('span',[_vm._v(_vm._s(_vm.$t("SendVerificationCode")))])],1):_c('span',{staticClass:"valid-methods-send-text",on:{"click":() => {
            _vm.getYzm();
          }}},[(_vm.loading)?_c('van-loading',{staticStyle:{"display":"inline-block"}}):_c('span',[_vm._v(_vm._s(_vm.$t("SendVerificationCode")))])],1)],1):_c('van-count-down',{attrs:{"time":_vm.time,"format":"ss"},on:{"finish":function($event){_vm.time = 0}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }