<template>
  <div class="app-cont">
    <transition name="top-hidden">
      <van-nav-bar
        v-if="$route.meta && $route.meta.tabHeader"
        fixed
        @click-left="
          () => {
            menuShow = true;
          }
        "
        @click-right="
          () => {
            $router.push({
              name: 'set',
            });
          }
        "
      >
        <template #left>
          <div class="layout-icon-wrap">
            <svg-icon iconClass="list" class="layout-icon"></svg-icon>
          </div>
        </template>
        <template #right>
          <div class="layout-icon-wrap">
            <svg-icon iconClass="set" class="layout-icon"></svg-icon>
          </div>
        </template>
        <template #title>
          {{ curWalletChain.chainName }}
        </template>
      </van-nav-bar>
    </transition>
    <AppMain></AppMain>
    <transition name="bottom-hidden">
      <van-tabbar v-if="$route.meta && $route.meta.tab" route>
        <van-tabbar-item icon="home-o" replace :to="{ name: 'home' }"
          >home</van-tabbar-item
        >
        <van-tabbar-item icon="search" replace :to="{ name: 'about' }"
          >about</van-tabbar-item
        >
        <van-tabbar-item icon="friends-o" replace :to="{ name: 'my' }"
          >my</van-tabbar-item
        >
      </van-tabbar>
    </transition>
    <van-popup
      v-model="menuShow"
      position="left"
      style="height: 100%; width: 80%"
    >
      <div class="chains-wrap">
        <div class="chains-title">{{ $t("ChooseAWallet") }}</div>
        <div class="chains">
          <div
            class="chains-item"
            v-for="(item, k) in walletChains"
            :key="k"
            @click="_selWalletChain(item)"
          >
            <div
              class="chains-item-before"
              :style="{
                'background-image': `url(${item.logo})`,
              }"
            ></div>
            <div class="chains-item-text">
              <div class="chains-item-text-top">{{ item.chainName }}</div>
              <TextHms class="chains-item-text-bottom"></TextHms>
            </div>
            <div class="chains-item-logo">
              <BaseImg :src="item.logo"></BaseImg>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <van-notify
      v-model="showAlert"
      :type="(showAlertMsg && showAlertMsg.type) || 'danger'"
    >
      <div class="t-notify">
        <div class="t-notify-text">
          <span>{{
            (showAlertMsg && showAlertMsg.msg) || $t("noPwdInfo")
          }}</span
          ><span
            @click="
              () => {
                showAlertMsg && showAlertMsg.func && showAlertMsg.func();
              }
            "
          >
            <span v-if="showAlertMsg.toMsg">
              {{ showAlertMsg.toMsg }}
            </span>
            <span v-else>
              {{ $t("noPwdInfoTo") }}
            </span>
            <van-icon name="arrow"
          /></span>
        </div>
        <van-icon
          name="cross"
          @click="
            () => {
              $alert('');
            }
          "
        />
      </div>
    </van-notify>
  </div>
</template>

<script>
// import getImgColor from "@/utils/getImgColor";
import AppMain from "./AppMain";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "LayoutIndex",
  components: {
    AppMain,
  },
  data() {
    return {
      // getImgColor,
      show: true,
      loading: false,
      curLangTitle: "",
      menuShow: false,
      showAlert: false,
      showAlertMsg: "",
    };
  },
  computed: {
    ...mapGetters(["walletChains", "curWalletChain", "alert"]),
  },
  watch: {
    alert: {
      immediate: true,
      deep: true,
      handler(val) {
        this.showAlert = !!val;
        if (!val) {
          setTimeout(() => {
            this.showAlertMsg = { ...val };
          }, 500);
        } else {
          this.showAlertMsg = val;
        }
      },
    },
  },
  methods: {
    ...mapActions("app", ["selWalletChain"]),
    getImgColor(k) {
      return [
        "#F7931A",
        "#627EEA",
        "#FF060A",
        "#000000",
        "#0AC18E",
        "#345D9D",
        "#0090FF",
      ][k];
    },
    _selWalletChain(item) {
      this.selWalletChain(item);
      this.menuShow = false;
    },
    async _logout() {
      this.loading = true;
      await this.logout().finally(() => {
        this.loading = false;
      });
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";

.app-cont {
  height: 100%;
  .avatar {
    margin-right: 15px;
  }
}
.top-hidden-enter-active,
.top-hidden-leave-active,
.bottom-hidden-enter-active,
.bottom-hidden-leave-active {
  // transition: all .5s;
  transition: all 0.8s cubic-bezier(0.86, 0.18, 0.82, 1.32);
}
.top-hidden-enter,
.top-hidden-leave-to {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}
.bottom-hidden-enter,
.bottom-hidden-leave-to {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}

.layout-icon {
  font-size: 22px;
}

.chains-wrap {
  padding-top: 30px;
  .chains-title {
    font-size: 17px;
    font-weight: 600;
    color: #101010;
    line-height: 24px;
    padding-left: 20px;
  }
  .chains {
    padding: 20px;
    padding-top: 28px;
    .chains-item {
      position: relative;
      height: 68px;
      background: #ff0000;
      border-radius: 8px;
      display: flex;
      font-size: 18px;
      padding: 10px 16px;
      border-radius: 6px;
      margin-bottom: 12px;
      overflow: hidden;
      &:nth-child(2) {
        background: #00d095;
      }
      .chains-item-before {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: 100%;
        background-position: center;
        filter: blur(50px);
        transform: scale(3);
        z-index: 0;
      }
      .chains-item-text {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        z-index: 2;
        padding-right: 40px;
        .chains-item-text-top {
          font-size: 16px;
          font-weight: 600;
          color: #ffffff;
          line-height: 22px;
        }
        .chains-item-text-bottom {
          font-size: 14px;
          color: #ffffff;
          line-height: 20px;
          text-indent: 2px;
        }
      }
      .base-img {
        position: absolute;
        right: 0px;
        top: 0px;
        height: 100%;
        opacity: 0.3;
      }
    }
  }
}

.t-notify {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  font-size: 12px;
  .t-notify-text {
    flex: 1;
    padding: 0 4px;
    .link {
      padding-left: 5px;
      text-decoration: underline;
    }
  }
  .van-icon.van-icon-cross {
    font-size: 16px;
  }
}
.child-view {
  height: 100%;
  background: red;
}
// width: 331px;
// height: 38px;
// background: #e83f3e;
// border-radius: 19px;
// @include svgShadow(red);
</style>
