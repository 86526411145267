export default [
  { id: "AF", label: "阿富汗", value: "+93", length: "9" },
  { id: "AL", label: "阿尔巴尼亚", value: "+355", length: "9" },
  { id: "DZ", label: "阿尔及利亚", value: "+213", length: "9" },
  { id: "AS", label: "美属萨摩亚", value: "+1684", length: "7" },
  { id: "AD", label: "安道尔", value: "+376", length: "9" },
  { id: "AO", label: "安哥拉", value: "+244", length: "9" },
  { id: "AI", label: "安圭拉", value: "+1264", length: "7" },
  { id: "AG", label: "安提瓜和巴布达", value: "+1268", length: "7" },
  { id: "AR", label: "阿根廷", value: "+54", length: "10" },
  { id: "AM", label: "亚美尼亚", value: "+374", length: "8" },
  { id: "AA", label: "阿鲁巴", value: "+297", length: "7" },
  { id: "AU", label: "澳大利亚", value: "+61", length: "9" },
  { id: "AT", label: "奥地利", value: "+43", length: "10" },
  { id: "AZ", label: "阿塞拜疆", value: "+994", length: "9" },
  { id: "BS", label: "巴哈马", value: "+1242", length: "7" },
  { id: "BH", label: "巴林", value: "+973", length: "8" },
  { id: "BD", label: "孟加拉国", value: "+880", length: "8" },
  { id: "BB", label: "巴巴多斯", value: "+1246", length: "7" },
  { id: "BY", label: "白俄罗斯", value: "+375", length: "9" },
  { id: "BE", label: "比利时", value: "+32", length: "9" },
  { id: "BZ", label: "伯利兹", value: "+501", length: "7" },
  { id: "BJ", label: "贝宁", value: "+229", length: "8" },
  { id: "BM", label: "百慕大群岛", value: "+1441", length: "7" },
  { id: "BT", label: "不丹", value: "+975", length: "8" },
  { id: "BO", label: "玻利维亚", value: "+591", length: "8" },
  { id: "BA", label: "波斯尼亚和黑塞哥维那", value: "+387", length: "8|9" },
  { id: "BW", label: "博茨瓦纳", value: "+267", length: "8" },
  { id: "BR", label: "巴西", value: "+55", length: "9" },
  { id: "BN", label: "文莱", value: "+673", length: "7" },
  { id: "BG", label: "保加利亚", value: "+359", length: "9" },
  { id: "BF", label: "布基纳法索", value: "+226", length: "8" },
  { id: "BI", label: "布隆迪", value: "+257", length: "8" },
  { id: "KH", label: "柬埔寨", value: "+855", length: "8|9" },
  { id: "CM", label: "喀麦隆", value: "+237", length: "9" },
  { id: "CA", label: "加拿大", value: "+1", length: "10", key: "Canada" },
  { id: "CV", label: "佛得角", value: "+238", length: "7" },
  { id: "KY", label: "开曼群岛", value: "+1345", length: "7" },
  { id: "CF", label: "中非共和国", value: "+236", length: "8" },
  { id: "TD", label: "乍得", value: "+235", length: "8" },
  { id: "CL", label: "智利", value: "+56", length: "9" },
  { id: "CN", label: "中国大陆", value: "+86", length: "11" },
  { id: "CO", label: "哥伦比亚", value: "+57", length: "10" },
  { id: "KM", label: "科摩罗", value: "+269", length: "7" },
  { id: "CK", label: "库克群岛", value: "+682", length: "5" },
  { id: "CR", label: "哥斯达黎加", value: "+506", length: "8" },
  { id: "CI", label: "科特迪瓦", value: "+225", length: "8" },
  { id: "HR", label: "克罗地亚", value: "+385", length: "9" },
  { id: "CW", label: "库拉索", value: "+599", length: "7" },
  { id: "CY", label: "塞浦路斯", value: "+357", length: "8" },
  { id: "CZ", label: "捷克", value: "+420", length: "9" },
  { id: "CD", label: "刚果民主共和国", value: "+243", length: "9" },
  { id: "DK", label: "丹麦", value: "+45", length: "8" },
  { id: "DJ", label: "吉布提", value: "+253", length: "8" },
  { id: "DM", label: "多米尼克", value: "+1767", length: "7" },
  { id: "DO", label: "多米尼加共和国", value: "+1809", length: "7" },
  { id: "EC", label: "厄瓜多尔", value: "+593", length: "7" },
  { id: "EG", label: "埃及", value: "+20", length: "10" },
  { id: "SV", label: "萨尔瓦多", value: "+503", length: "8" },
  { id: "GQ", label: "赤道几内亚", value: "+240", length: "9" },
  { id: "ER", label: "厄立特里亚", value: "+291", length: "7" },
  { id: "EE", label: "爱沙尼亚", value: "+372", length: "7|8" },
  { id: "SZ", label: "斯威士兰", value: "+268", length: "8" },
  { id: "ET", label: "埃塞俄比亚", value: "+251", length: "9" },
  { id: "FO", label: "法罗群岛", value: "+298", length: "6" },
  { id: "FJ", label: "斐济", value: "+679", length: "7" },
  { id: "FI", label: "芬兰", value: "+358", length: "9" },
  { id: "FR", label: "法国", value: "+33", length: "9" },
  { id: "GF", label: "法属圭亚那", value: "+594", length: "9" },
  { id: "PF", label: "法属波利尼西亚", value: "+689", length: "8" },
  { id: "GA", label: "加蓬", value: "+241", length: "8" },
  { id: "GM", label: "冈比亚", value: "+220", length: "7" },
  { id: "GE", label: "格鲁吉亚", value: "+995", length: "9" },
  { id: "DE", label: "德国", value: "+49", length: "11" },
  { id: "GH", label: "加纳", value: "+233", length: "9" },
  { id: "GI", label: "直布罗陀", value: "+350", length: "8" },
  { id: "GR", label: "希腊", value: "+30", length: "10" },
  { id: "GL", label: "格陵兰岛", value: "+299", length: "6" },
  { id: "GD", label: "格林纳达", value: "+1473", length: "7" },
  { id: "GP", label: "瓜德罗普岛", value: "+590", length: "9" },
  { id: "GU", label: "关岛", value: "+1671", length: "7" },
  { id: "GT", label: "危地马拉", value: "+502", length: "8" },
  { id: "GN", label: "几内亚", value: "+224", length: "9" },
  { id: "GW", label: "几内亚比绍共和国", value: "+245", length: "9" },
  { id: "GY", label: "圭亚那", value: "+592", length: "7" },
  { id: "HT", label: "海地", value: "+509", length: "8" },
  { id: "HN", label: "洪都拉斯", value: "+504", length: "8" },
  { id: "HK", label: "中国香港", value: "+852", length: "8" },
  { id: "HU", label: "匈牙利", value: "+36", length: "9" },
  { id: "IS", label: "冰岛", value: "+354", length: "7" },
  { id: "IN", label: "印度", value: "+91", length: "10" },
  { id: "ID", label: "印度尼西亚", value: "+62", length: "10|11" },
  { id: "IQ", label: "伊拉克", value: "+964", length: "10" },
  { id: "IE", label: "爱尔兰", value: "+353", length: "9" },
  { id: "IL", label: "以色列", value: "+972", length: "9" },
  { id: "IT", label: "意大利", value: "+39", length: "10" },
  { id: "JM", label: "牙买加", value: "+1876", length: "7" },
  { id: "JP", label: "日本", value: "+81", length: "10" },
  { id: "JO", label: "约旦", value: "+962", length: "9" },
  { id: "KZ", label: "哈萨克斯坦", value: "+7", length: "10" },
  { id: "KE", label: "肯尼亚", value: "+254", length: "9" },
  { id: "KI", label: "基里巴斯", value: "+686", length: "7" },
  { id: "KW", label: "科威特", value: "+965", length: "8" },
  { id: "KG", label: "吉尔吉斯斯坦", value: "+996", length: "9" },
  { id: "LA", label: "老挝", value: "+856", length: "10" },
  { id: "LV", label: "拉脱维亚", value: "+371", length: "8" },
  { id: "LB", label: "黎巴嫩", value: "+961", length: "8" },
  { id: "LS", label: "莱索托", value: "+266", length: "8" },
  { id: "LR", label: "利比里亚", value: "+231", length: "9" },
  { id: "LY", label: "利比亚", value: "+218", length: "8" },
  { id: "LI", label: "列支敦士登", value: "+423", length: "7" },
  { id: "LT", label: "立陶宛", value: "+370", length: "8" },
  { id: "LU", label: "卢森堡", value: "+352", length: "9" },
  { id: "MO", label: "中国澳门", value: "+853", length: "8" },
  { id: "MK", label: "北马其顿", value: "+389", length: "8" },
  { id: "MG", label: "马达加斯加", value: "+261", length: "9" },
  { id: "MW", label: "马拉维", value: "+265", length: "9" },
  { id: "MY", label: "马来西亚", value: "+60", length: "9|10" },
  { id: "MV", label: "马尔代夫", value: "+960", length: "7" },
  { id: "ML", label: "马里", value: "+223", length: "8" },
  { id: "MT", label: "马耳他", value: "+356", length: "8" },
  { id: "MQ", label: "马提尼克", value: "+596", length: "9" },
  { id: "MR", label: "毛里塔尼亚", value: "+222", length: "8" },
  { id: "MU", label: "毛里求斯", value: "+230", length: "8" },
  { id: "YT", label: "马约特", value: "+262", length: "9", key: "Mayotte" },
  { id: "MX", label: "墨西哥", value: "+52", length: "10" },
  { id: "MD", label: "摩尔多瓦", value: "+373", length: "8" },
  { id: "MC", label: "摩纳哥", value: "+377", length: "8" },
  { id: "MN", label: "蒙古", value: "+976", length: "8" },
  { label: "黑山", value: "+382", length: "8", id: "ME" },
  { id: "MS", label: "蒙特塞拉特岛", value: "+1664", length: "7" },
  { id: "MA", label: "摩洛哥", value: "+212", length: "9" },
  { id: "MZ", label: "莫桑比克", value: "+258", length: "9" },
  { id: "MM", label: "缅甸", value: "+95", length: "10" },
  { id: "NA", label: "纳米比亚", value: "+264", length: "9" },
  { id: "NP", label: "尼泊尔", value: "+977", length: "10" },
  { id: "NL", label: "荷兰", value: "+31", length: "9" },
  { id: "NC", label: "新喀里多尼亚", value: "+687", length: "6" },
  { id: "NZ", label: "新西兰", value: "+64", length: "8|9|10" },
  { id: "NI", label: "尼加拉瓜", value: "+505", length: "8" },
  { id: "NE", label: "尼日尔", value: "+227", length: "8" },
  { id: "NG", label: "尼日利亚", value: "+234", length: "10" },
  { id: "NO", label: "挪威", value: "+47", length: "8" },
  { id: "OM", label: "阿曼", value: "+968", length: "8" },
  { id: "PK", label: "巴基斯坦", value: "+92", length: "10" },
  { id: "PW", label: "帕劳", value: "+680", length: "7" },
  { id: "", label: "巴勒斯坦", value: "+970", length: "9" },
  { id: "PA", label: "巴拿马", value: "+507", length: "8" },
  { id: "PG", label: "巴布亚新几内亚", value: "+675", length: "8" },
  { id: "PY", label: "巴拉圭", value: "+595", length: "9" },
  { id: "PE", label: "秘鲁", value: "+51", length: "9" },
  { id: "PH", label: "菲律宾", value: "+63", length: "10" },
  { id: "PL", label: "波兰", value: "+48", length: "9" },
  { id: "PT", label: "葡萄牙", value: "+351", length: "9" },
  { id: "PR", label: "波多黎各", value: "+1787", length: "7" },
  { id: "QA", label: "卡塔尔", value: "+974", length: "8" },
  { id: "CG", label: "刚果共和国", value: "+242", length: "9" },
  { id: "RE", label: "留尼汪", value: "+262", length: "9", key: "Reunion" },
  { id: "RO", label: "罗马尼亚", value: "+40", length: "9" },
  { id: "RW", label: "卢旺达", value: "+250", length: "9" },
  { id: "KN", label: "圣基茨和尼维斯", value: "+1869", length: "7" },
  { id: "LC", label: "圣露西亚", value: "+1758", length: "7" },
  { id: "PM", label: "圣彼埃尔和密克隆岛", value: "+508", length: "6" },
  { id: "VC", label: "圣文森特和格林纳丁斯", value: "+1784", length: "7" },
  { id: "WS", label: "萨摩亚", value: "+685", length: "7" },
  { id: "SM", label: "圣马力诺", value: "+378", length: "8" },
  { id: "ST", label: "圣多美和普林西比", value: "+239", length: "7" },
  { id: "SA", label: "沙特阿拉伯", value: "+966", length: "9" },
  { id: "SN", label: "塞内加尔", value: "+221", length: "7" },
  { id: "", label: "塞尔维亚", value: "+381", length: "9" },
  { id: "SC", label: "塞舌尔", value: "+248", length: "7" },
  { id: "SL", label: "塞拉利昂", value: "+232", length: "8" },
  { id: "SG", label: "新加坡", value: "+65", length: "8" },
  { id: "", label: "圣马丁岛（荷兰部分）", value: "+1721", length: "7" },
  { id: "SK", label: "斯洛伐克", value: "+421", length: "9" },
  { id: "SI", label: "斯洛文尼亚", value: "+386", length: "8" },
  { id: "SB", label: "所罗门群岛", value: "+677", length: "7" },
  { id: "SO", label: "索马里", value: "+252", length: "9" },
  { id: "ZA", label: "南非", value: "+27", length: "9" },
  { id: "KR", label: "韩国", value: "+82", length: "10" },
  { id: "ES", label: "西班牙", value: "+34", length: "9" },
  { id: "LK", label: "斯里兰卡", value: "+94", length: "9" },
  { id: "SR", label: "苏里南", value: "+597", length: "7" },
  { id: "SE", label: "瑞典", value: "+46", length: "9" },
  { id: "CH", label: "瑞士", value: "+41", length: "9" },
  { id: "TW", label: "中国台湾", value: "+886", length: "9" },
  { id: "TJ", label: "塔吉克斯坦", value: "+992", length: "9" },
  { id: "TZ", label: "坦桑尼亚", value: "+255", length: "9" },
  { id: "TH", label: "泰国", value: "+66", length: "9" },
  { id: "TP", label: "东帝汶", value: "+670", length: "8" },
  { id: "TG", label: "多哥", value: "+228", length: "8" },
  { id: "TO", label: "汤加", value: "+676", length: "7" },
  { id: "TT", label: "特立尼达和多巴哥", value: "+1868", length: "7" },
  { id: "TN", label: "突尼斯", value: "+216", length: "8" },
  { id: "TR", label: "土耳其", value: "+90", length: "10" },
  { id: "TM", label: "土库曼斯坦", value: "+993", length: "8" },
  { id: "TC", label: "特克斯和凯科斯群岛", value: "+1649", length: "7" },
  { id: "UG", label: "乌干达", value: "+256", length: "9" },
  { id: "UA", label: "乌克兰", value: "+380", length: "9" },
  { id: "AE", label: "阿拉伯联合酋长国", value: "+971", length: "9" },
  { id: "GB", label: "英国", value: "+44", length: "10" },
  { id: "US", label: "美国", value: "+1", length: "10", key: "UnitedStates" },
  { id: "UY", label: "乌拉圭", value: "+598", length: "8" },
  { id: "UZ", label: "乌兹别克斯坦", value: "+998", length: "9" },
  { id: "VU", label: "瓦努阿图", value: "+678", length: "7" },
  { id: "VE", label: "委内瑞拉", value: "+58", length: "10" },
  { id: "VN", label: "越南", value: "+84", length: "9" },
  { id: "VG", label: "英属维尔京群岛", value: "+1284", length: "7" },
  { id: "VI", label: "美属维尔京群岛", value: "+1340", length: "7" },
  { id: "YE", label: "也门", value: "+967", length: "9" },
  { id: "ZM", label: "赞比亚", value: "+260", length: "9" },
  { id: "ZW", label: "津巴布韦", value: "+263", length: "9" },
];
