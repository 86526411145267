import request from "@/utils/request.js";

export function chainList(data) {
  return request({ method: "post", url: "/api/wallet/chainList", data });
}

export function getChainTokenList(data) {
  return request({
    method: "post",
    url: "/api/wallet/getChainTokenList",
    data,
  });
}

export function balance(data) {
  return request({ method: "post", url: "/api/wallet/balance", data });
}

export function balanceForProject(data) {
  return request({
    method: "post",
    url: "/api/wallet/balanceForProject",
    data,
  });
}

export function withdraw(data) {
  return request({ method: "post", url: "/api/wallet/withdraw", data });
}

export function withdrawAsk(data) {
  return request({ method: "post", url: "/api/wallet/withdrawAsk", data });
}

export function transferIn(data) {
  return request({ method: "post", url: "/api/wallet/transferIn", data });
}

export function transferOut(data) {
  return request({ method: "post", url: "/api/wallet/transferOut", data });
}

export function getTokenRecordList(data) {
  return request({
    method: "post",
    url: "/api/wallet/getTokenRecordList",
    data,
  });
}

export function getTokenRecordListById(data) {
  return request({
    method: "post",
    url: "/api/wallet/getTokenRecordListById",
    data,
  });
}

export function getTokenRecordDetail(data) {
  return request({
    method: "post",
    url: "/api/wallet/getTokenRecordDetail",
    data,
  });
}

export function recordByClientSerialNo(data) {
  return request({
    method: "post",
    url: "/api/wallet/recordByClientSerialNo",
    data,
  });
}

export function getValidTransferTokens(data) {
  return request({
    method: "post",
    url: "/api/wallet/getValidTransferTokens",
    data,
  });
}

// export function login(data, appKey){
//   return request({method: 'post',url:'/api/wallet/login', data, {noAuth:true, token:appKey});
// }

// 獲取平臺支援的鏈列表
export function walletGetChainList(data) {
  return request({ method: "post", url: "/api/wallet/getChainList", data });
}

// 獲取商家支持的兌換幣種列表
export function walletGetValidExchangeTokens(data) {
  return request({
    method: "post",
    url: "/api/wallet/getValidExchangeTokens",
    data,
  });
}

// 兌換問價
export function walletExchangeAsk(data) {
  return request({ method: "post", url: "/api/wallet/exchangeAsk", data });
}

// 兌換
export function walletExchange(data) {
  return request({ method: "post", url: "/api/wallet/exchange", data });
}

// 地址本-列表
export function walletAddressList(data) {
  return request({ method: "post", url: "/api/wallet/address/list", data });
}

// 地址本-新增
export function walletAddressAdd(data) {
  return request({ method: "post", url: "/api/wallet/address/add", data });
}

// 地址本-修改
export function walletAddressEdit(data) {
  const opt = { ...data };
  delete opt.id;
  return request({
    method: "post",
    url: `/api/wallet/address/edit/${data.id}`,
    data: opt,
  });
}

// 地址本-删除
export function walletAddressDel(data) {
  const opt = { ...data };
  delete opt.id;
  return request({ method: "post", url: `/api/wallet/address/del/${data.id}` });
}

// 用戶儲值到平臺
export function walletTransferIn(data) {
  return request({ method: "post", url: `/api/wallet/transferIn`, data });
}

// 检测地址合法性
export function walletAddressCheck(data) {
  return request({ method: "post", url: `/api/wallet/address/check`, data });
}

export function login(data, appKey) {
  return request({
    method: "post",
    url: `/api/wallet/login`,
    data,
    headers: {
      Authorization: appKey,
    },
  });
}

// 獲取商家支持的充提幣種列表
export function walletGetValidTransferTokens(data) {
  return request({
    method: "post",
    url: `/api/wallet/getValidTransferTokens`,
    data,
  });
}
