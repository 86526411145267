/* eslint-disable */
import { i18n } from "@/langs/index";
import store from "@/store";
import { walletAddressCheck } from "@/api/wallet.js";
import { Toast } from "vant";

export const Loading = (st = 1, msg) => {
  if (st) {
    Toast.loading({
      message: msg || `${i18n.tc("inOperation")}...`,
      forbidClick: true,
      duration: 0,
    });
  } else {
    Toast.clear();
  }
};

export function validEmail(email) {
  const reg =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email) ? "" : i18n.tc("emailErr");
}
/**
 * @param {string} path
 * @returns {Boolean}
 * @des 验证手机号码
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 * @des 验证用户名
 */
export function validUsername(str) {
  const valid_map = ["admin", "joke"];
  return valid_map.indexOf(str.trim()) >= 0;
}

export const BitNum = (str) => {
  let count = 0;
  const arr = str.split("");
  arr.forEach((item) => {
    count += Math.ceil(item.charCodeAt().toString(2).length / 8);
  });
  return count;
};

const freely = (fn, trigger = "onBlur") => {
  const vFn = (val, rule) => {
    return !fn(val, rule);
  };
  return { validator: vFn, message: fn, trigger };
};
const asyncFreely = (fn, msg, trigger = "onBlur") => {
  const vFn = async (val, rule) => {
    return !(await fn(val, rule));
  };
  const vMsg = (msg && msg()) || "";
  return { validator: vFn, message: vMsg, trigger };
};
const getDigit = (val) => {
  const nStrArr = (val + "").split(".");
  const integerBitLength = nStrArr[0].length;
  const decimalLength = (nStrArr[1] && nStrArr[1].length) || 0;
  return [integerBitLength, decimalLength];
};

export const limitWx = (beforeNum = 8, afterNum = 8) => {
  return (val) => {
    const ws = getDigit(val);
    if (ws[1] > afterNum) {
      return `${i18n.tc("NoMoreThanDecimalSeparator")} ${afterNum} ${i18n.tc(
        "position"
      )}, ${i18n.tc("PleaseCorrect")}`;
    }
    if (ws[0] > beforeNum) {
      return `${i18n.tc("IntegerBitsCannotExceed")} ${beforeNum} ${i18n.tc(
        "position"
      )}, ${i18n.tc("PleaseCorrect")}`;
    }
    return "";
  };
};

export const $rule = {
  freely,
  asyncFreely,
  required: (cfn) =>
    freely((val) => {
      if ([null, "", undefined].includes(val)) {
        cfn && cfn(false);
        return i18n.tc("emptyErr");
      }
      cfn && cfn(true);
      return "";
    }),
  sum: (beforeNum = 8, afterNum = 8) => {
    return freely(limitWx(beforeNum, afterNum));
  },
  email: () => freely((email) => validEmail(email)),
  phone: (getPhoneOptionFn, cfn) =>
    freely((val) => {
      const { area } = getPhoneOptionFn();
      const phoneAreaList = store.getters.phoneAreaList;
      if ([null, undefined, ""].includes(area)) {
        cfn && cfn(false);
        return `${i18n.tc("pleaseSelectAnAreaCode")}`;
      }
      const fItem = phoneAreaList.find((item) => {
        return area === item.value;
      });
      if (!fItem) {
        cfn && cfn(true);
        return "";
      }
      const fArr = fItem.length.split("|");
      const hasLength = fArr.find((length) => +length === val.length);
      cfn && cfn(!!hasLength);
      return hasLength
        ? ""
        : `${i18n.tc("pleaseEnter")}${fArr.join("-")}${i18n.tc("numbers")}`;
    }),
  length: ([start, end] = [0, 99999], cfn) =>
    freely((val) => {
      const num = BitNum(val);
      if (num < start) {
        cfn && cfn(false);
        return `${i18n.tc("CannotBeLessThan")} ${start} ${i18n.tc("Byte")}`;
      }
      if (num > end) {
        cfn && cfn(false);
        return `${i18n.tc("CannotBeGreaterThan")} ${end} ${i18n.tc("Byte")}`;
      }
      cfn && cfn(true);
      return "";
    }),
  address: (cfn) =>
    freely((val) => {
      const isFormat = /^[A-Za-z0-9]*$/.test(val)
        ? ""
        : i18n.tc("PleaseEnterTheCorrectDeliveryAddress");
      if (isFormat) {
        cfn && cfn(isFormat);
        return isFormat;
      }
      return "";
    }),
  asyncAddress: (cfn) =>
    asyncFreely(
      async (val) => {
        Loading(1, i18n.tc("addressCheck"));
        cfn && cfn(false);
        console.log(
          store.getters.curWalletChain.chain,
          "store.getters.curWalletChain.chain"
        );
        let st = 1;
        const res = await walletAddressCheck({
          chain: store.getters.curWalletChain.chain,
          address: val,
        })
          .catch((res) => {
            if (res.code === "ERR_NETWORK") {
              st = false;
            }
          })
          .finally(() => {
            Loading(0);
          });
        const resultSt = !!res || st === false;
        cfn && cfn(resultSt);
        return !resultSt;
      },
      () => i18n.tc("TheAddressIsIllegal")
    ),
};
