import router from "./router";
import store from "./store";
import { Notify } from "vant";
import NProgress from "nprogress"; // 进度条
import "nprogress/nprogress.css"; // 进度条样式
NProgress.configure({ showSpinner: false }); // 进度条配置
import { getToken } from "@/utils/auth"; // 从cookie中获取令牌
import getPageTitle from "@/utils/get-page-title";
import { i18n } from "@/langs/index";
import { getCodeMsg } from "@/utils/request";
import ModuleLoading from "@/components/ModuleLoading/index.js";

const whiteList = ["/login", "/getLink"]; // 无重定向白名单

router.beforeEach(async (to, from, next) => {
  // 进度条开始
  NProgress.start();

  // 设置页面标题
  document.title = getPageTitle(i18n.tc(to.meta.i18nTitle));
  const token = to.query.token;
  if (!["", null, undefined].includes(token)) {
    // store.dispatch("user/logout");
    store.dispatch("app/clearCookies");
    store.dispatch("user/setToken", { token });
  }
  // 判断用户是否已登录
  const hasToken = getToken();
  if (hasToken) {
    if (to.path === "/login") {
      // 如果已登录，则重定向到首页
      next({ path: "/" });
      NProgress.done();
    } else {
      const hasGetUserInfo = !!store.getters.user;
      if (hasGetUserInfo) {
        next();
      } else {
        try {
          // 获取用户信息
          await store
            .dispatch("user/getUserInfo")
            .then(() => {})
            .catch(() => {
              ModuleLoading.end();
            });
          next();
        } catch (error) {
          console.log(error.code);
          // 删除令牌，转到登录页面重新登录
          await store.dispatch("user/logout");
          Notify({
            type: "danger",
            message: getCodeMsg(error.code) || error.message || "Has Error",
          });
          next(`/login?redirect=${to.path}`);
          NProgress.done();
        }
      }
    }
  } else {
    /* 没有令牌*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 在登录白名单中，直接跳转
      next();
    } else {
      // 其他没有访问权限的页面将被重定向到登录页面。
      next(`/login?redirect=${to.path}`);
      NProgress.done();
      ModuleLoading.end();
    }
  }
});

router.afterEach(() => {
  // 完成进度条
  NProgress.done();
});
