<template>
  <div
    class="base-list"
    :class="{
      'arr-list': isArrData,
    }"
    ref="baseList"
    :style="listHeight ? `height: ${listHeight}px;` : ''"
  >
    <components
      v-model="listCore.refreshing"
      @refresh="
        () => {
          $emit('refresh');
          listRefresh();
        }
      "
      :is="refresh ? 'van-pull-refresh' : 'div'"
      :success-text="$t('RefreshSuccessful')"
    >
      <van-list
        :finished="listCore.finished"
        :finished-text="isArrData ? '' : $t(listCore.finishedText)"
        v-model="listCore.loading"
        :error.sync="listCore.error"
        @load="
          () => {
            listCore.load().then(() => {
              $emit('change', listCore);
            });
          }
        "
        :error-text="$t('RequestFailedClickToReload')"
      >
        <van-skeleton
          title
          :row="3"
          :loading="!listCore.inited"
          style="width: 85%; margin: auto"
        >
          <slot name="list" :data="listCore.data">
            <div v-for="(item, k) in listCore.data" :key="k" :title="item">
              <slot :item="item"></slot>
            </div>
          </slot>
        </van-skeleton>
        <div
          v-if="!listCore.data.length && !listCore.loading"
          style="width: 109px; margin: auto; margin-top: 40px"
        >
          <van-empty :description="$t('noData')" />
        </div>
        <template #finished>
          <span v-if="!listCore.data.length"></span>
        </template>
      </van-list>
    </components>
  </div>
</template>

<script>
import listHelper from "./listHelper";

export default {
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    refresh: {
      type: Boolean,
      default: false,
    },
    initH: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      listCore: "",
      listHeight: 0,
      finished: false,
    };
  },
  computed: {
    isArrData() {
      return Array.isArray(this.listCore.getData);
    },
  },
  mounted() {
    if (!this.initH) {
      return;
    }
    setTimeout(() => {
      this.refreshView();
    }, 100);
  },
  methods: {
    async listRefresh() {
      this.refreshView();
      await this.listCore.refresh().then(() => {
        this.$emit("change", this.listCore);
      });
    },
    refreshView() {
      if (this.$refs.baseList) {
        this.listHeight = this.$refs.baseList.getBoundingClientRect().height;
      }
    },
  },
  watch: {
    options: {
      deep: true,
      immediate: true,
      handler(val) {
        this.listCore = new listHelper({ ...val });
        this.$emit("init", this.listCore);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.base-list {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 50px;
  &.arr-list {
    padding-bottom: 0;
  }
}
</style>
