import en from "@/langs/mobilePhoneArea/en.js";
import { i18n } from "@/langs/index";

const getters = {
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,
  token: (state) => state.user.token,
  user: (state) => state.user.user,
  appCurrencys: (state) => state.app.currencys,
  appCurCurrency: (state) => state.app.curCurrency,
  walletChains: (state) => state.app.walletChains,
  curWalletChain: (state) => state.app.curWalletChain,
  tokens: (state) => state.app.tokens,
  addressList: (state) => state.app.addressList,
  cViews: (state) => state.app.cViews,
  phoneAreaList: () =>
    en.map((item) => {
      const enStr = item.label;
      enStr.replace(/[^a-zA-Z]*/g, "");
      return {
        ...item,
        label: i18n.tc(`phoneAreas.${item.key || enStr}`),
      };
    }),
  alert: (state) => state.app.alert,
};
export default getters;
