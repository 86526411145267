// import parseTime, formatTime and set to filter
// export { parseTime, formatTime } from '@/utils'
import moment from "dayjs";
import NP from "@/utils/NP.js";
import store from "@/store";

export function toFixed(num, s, floor = false) {
  var times = Math.pow(10, s);
  // 0.5 为了舍入
  var des = num * times + (!floor ? 0.5 : 0);
  // 去除小数
  des = parseInt(des, 10) / times;
  return des + "";
}

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + "s";
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), " minute");
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), " hour");
  } else {
    return pluralize(~~(between / 86400), " day");
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1e18, symbol: "E" },
    { value: 1e15, symbol: "P" },
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "G" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "k" },
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value)
          .toFixed(digits)
          .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[i].symbol
      );
    }
  }
  return num.toString();
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0)
    .toString()
    .replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","));
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function FixedFloor(num, toNum = 2, clear = false, minToNum) {
  if (["", null, undefined].includes(num)) {
    return clear
      ? minToNum
        ? toFixed(0, minToNum, 1)
        : 0
      : toFixed(0, toNum, 1);
  }
  const curNum = toFixed(+num, toNum, 1);
  if (!clear) {
    return curNum;
  }
  // clear模式 浮点数小数位大于toNum的去除，小于的直接返回
  const resultPreCurNum = +curNum;
  if (minToNum) {
    const arr1 = (resultPreCurNum + "").split(".")[1] || { length: 0 };
    if (arr1 && arr1.length < minToNum) {
      return toFixed(resultPreCurNum, minToNum, 1);
    }
  }
  return resultPreCurNum;
}

export function Fixed(num, toNum = 2, clear = false, minToNum) {
  if (["", null, undefined].includes(num)) {
    return clear ? (minToNum ? toFixed(0, minToNum) : 0) : toFixed(0, toNum);
  }
  const curNum = toFixed(+num, toNum);
  if (!clear) {
    return curNum;
  }
  // clear模式 浮点数小数位大于toNum的去除，小于的直接返回
  const resultPreCurNum = +curNum;
  if (minToNum) {
    const arr1 = (resultPreCurNum + "").split(".")[1] || { length: 0 };
    if (arr1 && arr1.length < minToNum) {
      return toFixed(resultPreCurNum, minToNum);
    }
  }
  return resultPreCurNum;
}

export function Money(num, prefix = "$", toNum = 2) {
  return prefix + Fixed(num, toNum);
}

export function HmStr(str, len1 = 3, len2 = 3, fillStr = "*") {
  if (!str) return "";
  const strLen = str.length;
  // if (strLen < (len1 + len2)) {
  //   return str
  // }
  if (strLen < len1 + len2) {
    return new Array(strLen).fill(fillStr).join("");
  }
  const stars = new Array(strLen - (len1 + len2)).fill(fillStr).join("");
  str = str.substr(0, len1) + stars + str.substr(strLen - len2);
  return str;
}

export function HmStrPhone(str, len1 = 3, len2 = 3, fillStr = "*") {
  if (!str) return "";
  const strLen = str.length;
  let l1 = len1;
  const l2 = len2;
  if (strLen <= 6) {
    l1 = 1;
  }
  return HmStr(str, l1, l2, fillStr);
}

export function HmStrEmail(str, len1 = 3, len2 = 5, fillStr = "*") {
  if (!str) return "";
  const strLen = str.length;
  let l1 = len1;
  const l2 = len2;
  if (strLen <= 8) {
    l1 = 1;
  }
  return HmStr(str, l1, l2, fillStr);
}

function isEmpty(val) {
  return [null, undefined, ""].includes(val);
}

export function FT(num, toNum = 2, clear = false, minToNum) {
  if (isEmpty(num)) {
    return Fixed(0, toNum, clear, minToNum);
  }
  const numArr = (num + "").split(".");
  numArr[0] = (+numArr[0] || 0)
    .toString()
    .replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","));
  numArr[1] =
    (
      Fixed(numArr[1] && +numArr[1] ? num : 0, toNum, clear, minToNum) + ""
    ).split(".")[1] || "";
  return numArr[0] + (numArr[1] === "" ? "" : `.${numArr[1]}`);
}

export function T(num) {
  return toThousandFilter(num);
}

export function toBi(num, jd) {
  return FT(num, jd || 4, true);
}

// export function toMoney(tNum) {
//   const num = toFixed(tNum, 4)
//   return FT(num, 4, true)
// }
// 金额过滤器
export function toMoney(tNum, prefix = true, aount = true) {
  const num = isNaN(+tNum) ? 0 : +tNum;
  // 清除末尾的0，最小两位，最多4位
  const rate = store.state.app.curCurrency.rate;
  const symbol1 = store.state.app.curCurrency.symbol1;
  return `${prefix ? symbol1 : ""} ${FT(
    aount ? NP.divide(num, rate) : num,
    4,
    true
  )}`;
}

export function showVal(item) {
  if (!item.balance) return 0;
  return toMoney(NP.times(item.balance, item.uPrice));
}

export function arrItem(
  val,
  { value = "value", name = "name", list = [] } = {}
) {
  const fItem = (list || []).find((item) => item[value] === val);
  return fItem ? fItem[name] : val;
}

export function objItem(val, obj = {}) {
  return obj[val] ? obj[val] : val;
}

export function date(hms) {
  return new moment(hms).format("YYYY-MM-DD HH:mm:ss");
}
