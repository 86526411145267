<template>
  <div class="currency-item">
    <base-img
      v-if="imgType === 'img'"
      zw
      class="currency-item-img"
      :src="item.img"
    ></base-img>
    <svg-icon
      v-else
      class="currency-item-img"
      :iconClass="item.img"
      :class="{
        red: !item.isIn,
        green: item.isIn,
      }"
    ></svg-icon>
    <div class="currency-item-msg">
      <div class="currency-item-left">
        <TextHms class="currency-item-title" :cont="item.name"></TextHms>
        <div class="currency-item-info">
          {{ item.date }}
        </div>
      </div>
      <div class="currency-item-right">
        <TextAutoSize
          class="currency-item-undulating"
          :class="{
            del: item.isIn !== undefined && !item.isIn,
            add: item.isIn !== undefined && item.isIn,
          }"
          :cont="
            (item.isIn !== undefined ? (item.isIn ? '+ ' : '- ') : '') +
              item.balance || ''
          "
        ></TextAutoSize>
        <div class="currency-item-info">{{ item.info }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    imgType: {
      type: String,
      default: "img",
    },
  },
};
</script>

<style lang="scss" scoped>
.currency-item {
  display: flex;
  box-sizing: border-box;
  padding: 18px 28px;
  padding-left: 12px;
  width: 100%;
  font-size: 14px;
  min-height: 94px;
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 18px;
  align-items: center;
  .currency-item-img {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    margin-right: 12px;
    border-radius: 12px;
    // border-radius: 12px;
    // &.red {
    //   border-radius: 50%;
    //   background: #ecb5b3;
    // }
    // &.green {
    //   border-radius: 50%;
    //   background: #b3e7da;
    // }
  }
  .currency-item-msg {
    flex: 1;
    display: flex;
    justify-content: space-between;
    .currency-item-left,
    .currency-item-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .currency-item-left {
      flex-shrink: 0;
      .currency-item-title {
        flex: 1;
        min-width: 100px;
        display: flex;
        align-items: center;
        font-weight: 400;
        color: #030319;
        line-height: 20px;
      }
      .currency-item-date {
        flex: 1;
      }
    }
    .currency-item-right {
      text-align: right;
    }
  }
  .currency-item-undulating {
    width: 100%;
    font-size: 16px;
    &.add {
      color: green;
    }
    &.del {
      color: red;
    }
    .van-icon {
      font-size: 12px;
      margin-right: -4px;
      vertical-align: middle;
    }
  }
  .currency-item-info {
    width: 100%;
    font-size: 12px;
    color: gray;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
}
</style>
