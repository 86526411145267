<template>
  <div>
    <BaseAlert
      ref="alert"
      :title="title"
      innerBtn
      :confirmBtn="![''].includes(confirmBtn) ? $t('confirm') : ''"
      :submit="
        (next) => {
          select(curSel);
          next();
        }
      "
    >
      <div class="list">
        <SelectsItem
          class="list-item"
          v-for="(item, k) in data"
          :key="k"
          :active="
            ![undefined, '', null].includes(curSel)
              ? curSel === item.val
              : item.val === defaultVal
          "
          @click.native="curSel = item.val"
        >
          <slot :item="item"></slot>
        </SelectsItem>
      </div>
    </BaseAlert>
  </div>
</template>

<script>
export default {
  props: {
    defaultVal: {
      type: [String, Number],
      default: "",
    },
    val: {
      type: [String, Number],
      default: "",
    },
    data: {
      type: [Array],
      default: () => [],
    },
    confirmBtn: {
      type: [String, Number],
      default: "confirm",
    },
    title: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      curSel: "",
    };
  },
  methods: {
    select(val) {
      this.$emit("change", val);
    },
    show() {
      this.$refs.alert.show();
    },
    hide() {
      this.$refs.alert.hide();
    },
  },
  watch: {
    val(val) {
      this.curSel = val;
    },
  },
};
</script>

<style scoped lang="scss">
.list {
  padding: 0 28px;
  padding-top: 17px;
  .list-item {
    height: 64px;
    background: #f7f7f7;
    border-radius: 12px;
    margin-bottom: 14px;
    font-size: 14px;
    font-weight: 600;
    color: #030319;
    line-height: 20px;
    display: flex;
    align-items: center;
    padding: 0 18px;
  }
}
</style>
