import Cookies from "js-cookie";

// 令牌名称
export const TokenKey = "authorization";

// 获取令牌
export function getToken() {
  return Cookies.get(TokenKey);
}

// 设置令牌
export function setToken(token, time = 1800) {
  let expires = new Date(new Date() * 1 + time * 1000);
  return Cookies.set(TokenKey, token, { expires: expires });
}

//移除令牌
export function removeToken() {
  return Cookies.remove(TokenKey);
}
