<template>
  <div>
    <ListSelects
      :title="$t('LanguageSettings')"
      ref="setLnag"
      :data="langs"
      :default-val="$i18n.locale"
      @change="select"
      v-slot="{ item }"
    >
      <div>
        {{ item.name }}
      </div>
    </ListSelects>
  </div>
</template>

<script>
import { langs } from "@/langs";
import getPageTitle from "@/utils/get-page-title";

export default {
  data() {
    return {
      curSel: "",
      langs: langs.map((item) => {
        return {
          name: item.title,
          val: item.locale[0],
        };
      }),
    };
  },
  computed: {
    langTitle() {
      const fItem = this.langs.find((item) => item.val === this.$i18n.locale);
      const langTitle = fItem ? fItem.name : "";
      return langTitle;
    },
  },
  mounted() {
    this.$emit("change", { title: this.langTitle });
    document.title = getPageTitle(this.$i18n.tc(this.$route.meta.i18nTitle));
  },
  methods: {
    select(val) {
      this.$locals(val);
      this.$emit("change", { title: this.langTitle });
      document.title = getPageTitle(this.$i18n.tc(this.$route.meta.i18nTitle));
    },
    show() {
      this.$refs.setLnag.show();
    },
    hide() {
      this.$refs.setLnag.hide();
    },
  },
};
</script>

<style scoped lang="scss">
.langs {
  padding: 0 28px;
  padding-top: 17px;
  .langs-item {
    height: 64px;
    background: #f7f7f7;
    border-radius: 12px;
    margin-bottom: 14px;
    font-size: 14px;
    font-weight: 600;
    color: #030319;
    line-height: 20px;
    display: flex;
    align-items: center;
    padding: 0 18px;
  }
}
</style>
