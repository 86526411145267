import defaultSettings from '@/settings'
import {i18n} from '@/langs/index'

const title = defaultSettings.i18nTitle||''

export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${i18n.tc(title)}`
  }
  return `${i18n.tc(title)}`
}
