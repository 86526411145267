import Vue from "vue";
import VueRouter from "vue-router";
/* 布局文件 */
import Layout from "@/layout/index";
import store from "@/store";
import { Notify } from "vant";
import { i18n } from "@/langs";
import { fault } from "./fault";
import ModuleLoading from "@/components/ModuleLoading/index.js";
const entryLazy = (componentImport) => {
  return () =>
    componentImport().catch(() => {
      Notify({
        message: i18n.tc("ModuleLoadingFailed"),
        type: "danger",
        duration: 5 * 1000,
      });
      ModuleLoading.end(componentImport);
    });
};

Vue.use(VueRouter);

// 没有权限要求的基本页面，所有角色都可以访问
const constantRoutes = [
  {
    path: "/login",
    name: "login",
    component: entryLazy(() => import("@/views/login/index")),
    hidden: true,
    meta: {
      title: "登陆",
    },
  },
  {
    path: "/getLink",
    name: "getLink",
    component: entryLazy(() => import("@/views/getLink")),
    hidden: true,
  },
  {
    path: "/404",
    name: "404",
    component: entryLazy(() =>
      import(/* webpackChunkName: "appHome" */ "@/views/404")
    ),
    hidden: true,
  },
  {
    path: "/",
    redirect: "/home",
    component: Layout,
    children: [
      {
        path: "home",
        name: "home",
        component: entryLazy(() =>
          import(/* webpackChunkName: "appHome" */ "@/views/home/index")
        ),
        meta: {
          // tab: 1,
          tabHeader: 1,
        },
      },
      {
        path: "homeDsc",
        name: "homeDsc",
        component: entryLazy(() =>
          import(/* webpackChunkName: "appBase" */ "@/views/home/homeDsc")
        ),
        meta: { i18nTitle: "AssetDetails", icon: "el-icon-s-data" },
      },
      {
        path: "homwDscDetail",
        name: "homwDscDetail",
        component: entryLazy(() =>
          import(/* webpackChunkName: "appBase" */ "@/views/home/homwDscDetail")
        ),
        meta: { i18nTitle: "ViewAssetDetails", icon: "el-icon-s-data" },
      },
      {
        path: "collectionEwm",
        name: "collectionEwm",
        component: entryLazy(() =>
          import(/* webpackChunkName: "appBase" */ "@/views/home/collectionEwm")
        ),
        meta: { i18nTitle: "Collection", icon: "el-icon-s-data" },
      },
      {
        path: "transferCurrency",
        name: "transferCurrency",
        component: entryLazy(() =>
          import(
            /* webpackChunkName: "appBase" */ "@/views/home/transferCurrency"
          )
        ),
        meta: { i18nTitle: "SelCurrencyList", icon: "el-icon-s-data" },
      },
      {
        path: "transferOut",
        name: "transferOut",
        component: entryLazy(() =>
          import(/* webpackChunkName: "appBase" */ "@/views/home/transferOut")
        ),
        meta: { i18nTitle: "transferAccounts", icon: "el-icon-s-data" },
      },
      {
        path: "walletsRecharge",
        name: "walletsRecharge",
        component: entryLazy(() =>
          import(
            /* webpackChunkName: "appBase" */ "@/views/home/walletsRecharge"
          )
        ),
        meta: { i18nTitle: "Recharge", icon: "el-icon-s-data" },
      },
      {
        path: "walletsExchange",
        name: "walletsExchange",
        component: entryLazy(() =>
          import(
            /* webpackChunkName: "appBase" */ "@/views/home/walletsExchange"
          )
        ),
        meta: { i18nTitle: "exchange", icon: "el-icon-s-data" },
      },
      {
        path: "about",
        name: "about",
        component: entryLazy(() => import("@/views/about/index")),
        // meta: {tab: 1, i18nTitle: 'about', icon: 'el-icon-s-data' },
      },
      {
        path: "my",
        name: "my",
        component: entryLazy(() => import("@/views/my/index")),
        // meta: {tab: 1, i18nTitle: 'my', icon: 'el-icon-s-data' },
      },
      {
        path: "set",
        name: "set",
        component: entryLazy(() =>
          import(/* webpackChunkName: "appSet" */ "@/views/set/index")
        ),
        meta: { i18nTitle: "SetUp", icon: "el-icon-s-data" },
      },
      {
        path: "setLangs",
        name: "setLangs",
        component: entryLazy(() =>
          import(/* webpackChunkName: "appSet" */ "@/views/set/setLangs")
        ),
        meta: { i18nTitle: "LanguageSettings", icon: "el-icon-s-data" },
      },
      {
        path: "setValuations",
        name: "setValuations",
        component: entryLazy(() =>
          import(/* webpackChunkName: "appSet" */ "@/views/set/setValuations")
        ),
        meta: { i18nTitle: "PricingSettings", icon: "el-icon-s-data" },
      },
      {
        path: "addressManage",
        name: "addressManage",
        component: entryLazy(() =>
          import(/* webpackChunkName: "appSet" */ "@/views/set/addressManage")
        ),
        meta: { i18nTitle: "AddressBookManagement", icon: "el-icon-s-data" },
      },
      {
        path: "editAddress",
        name: "editAddress",
        component: entryLazy(() =>
          import(/* webpackChunkName: "appSet" */ "@/views/set/editAddress")
        ),
        meta: {
          i18nTitle: "AddressBookManagement",
          icon: "el-icon-s-data",
          keepAlive: false,
        },
      },
      {
        path: "selAddressType",
        name: "selAddressType",
        component: entryLazy(() =>
          import(/* webpackChunkName: "appSet" */ "@/views/set/selAddressType")
        ),
        meta: { i18nTitle: "chainType", icon: "el-icon-s-data" },
      },
      {
        path: "securitySetting",
        name: "securitySetting",
        component: entryLazy(() =>
          import(/* webpackChunkName: "appSet" */ "@/views/set/securitySetting")
        ),
        meta: { i18nTitle: "securitySetting", icon: "el-icon-s-data" },
      },
      {
        path: "verificationPageEmail",
        name: "verificationPageEmail",
        component: entryLazy(() =>
          import(
            /* webpackChunkName: "appSet" */ "@/views/set/verificationPageEmail"
          )
        ),
        meta: { i18nTitle: "Email", icon: "el-icon-s-data" },
      },
      {
        path: "verificationPagePhone",
        name: "verificationPagePhone",
        component: entryLazy(() =>
          import(
            /* webpackChunkName: "appSet" */ "@/views/set/verificationPagePhone"
          )
        ),
        meta: { i18nTitle: "mobilePhone", icon: "el-icon-s-data" },
      },
      {
        path: "verificationPagePwd",
        name: "verificationPagePwd",
        component: entryLazy(() =>
          import(
            /* webpackChunkName: "appSet" */ "@/views/set/verificationPagePwd"
          )
        ),
        meta: { i18nTitle: "mobileAccountPassword", icon: "el-icon-s-data" },
      },
    ],
  },
  // 404 page必须放在末尾
  { path: "*", redirect: "/404", hidden: true },
];

const createRouter = () =>
  new VueRouter({
    // mode: 'history', // 需要后台支持
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

const routerBack = VueRouter.prototype.back;
VueRouter.prototype.back = function (location) {
  router.isBack = true;
  return routerBack.call(this, location);
};
const routerGo = VueRouter.prototype.go;
VueRouter.prototype.go = function (num = 0) {
  if (num === -1) {
    router.isBack = true;
  }
  return routerGo.call(this, num);
};

export function resetRouter() {
  const newRouter = createRouter();
  // 重置路由器
  router.matcher = newRouter.matcher;
}

router.beforeEach((to, from, next) => {
  console.log(to, from);
  ModuleLoading.start();
  fault(to);
  store.dispatch("app/setAlert", "");
  next();
});

router.afterEach(() => {
  fault("");
  ModuleLoading.end();
});

export default router;
