<template>
  <div
    class="valid-methods"
    :class="{
      'valid-methods-hide': hide,
    }"
  >
    <div>
      <div v-if="time < 1">
        <van-button
          v-if="!textBtn"
          size="small"
          type="info"
          @click="
            () => {
              getYzm();
            }
          "
          class="valid-methods-send-btn"
        >
          <van-loading v-if="loading" style="display: inline-block" />
          <span v-else>{{ $t("SendVerificationCode") }}</span>
        </van-button>
        <span
          v-else
          class="valid-methods-send-text"
          @click="
            () => {
              getYzm();
            }
          "
        >
          <van-loading v-if="loading" style="display: inline-block" />
          <span v-else>{{ $t("SendVerificationCode") }}</span>
        </span>
      </div>
      <van-count-down v-else :time="time" format="ss" @finish="time = 0" />
    </div>
  </div>
</template>

<script>
import {
  walletSendVerificationCode,
  walletCheckVerificationCode,
} from "@/api/sms.js";
// import { getCodeMsg } from "@/utils/request";

const validMethods = [
  {
    codes: [10, 20, 21],
    sendMethod: "phone",
  },
  {
    codes: [11, 30, 31],
    sendMethod: "email",
  },
  {
    codes: [40, 50, 60],
    sendMethod: "password",
  },
];

export default {
  props: {
    hide: {
      type: Boolean,
      default: false,
    },
    textBtn: {
      type: Boolean,
      required: false,
    },
    type: {
      type: Number,
      required: true,
    },
    sendFn: {
      type: [Function, String],
      default: "",
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      // 倒计时
      time: 0,
      yzm: "",
      validMethods: [],
      verifyMethod: "",
      loading: false,
    };
  },
  computed: {
    // 验证方式的号码
    verifyMethodNumber() {
      const item =
        this.validMethods.find(
          (item) => item.sendMethod === this.verifyMethod
        ) || {};
      return item[this.verifyMethod];
    },
  },
  methods: {
    async yz(num) {
      let st = 1;
      await walletCheckVerificationCode({
        code: num,
        sendMethod: this.verifyMethod,
        type: this.type,
        ...this.data,
      }).catch((res) => {
        if (res.code === "ERR_NETWORK") {
          st = false;
        } else {
          st = 0;
        }
      });
      return st;
    },
    // 获取验证码
    async getYzm() {
      // 10绑定手机, 11绑定Email, 20修改手机号码时，验证旧手机, 21修改手机号码时，验证新手机, 30修改Email时，验证旧Email,
      // 31修改Email时，验证新Email, 40提币验证, 50用户储值到商家验证, 60设置动帐密码验证
      this.loading = true;
      const pr = this.sendFn
        ? this.sendFn({
            sendMethod: this.verifyMethod,
            type: this.type,
            ...this.data,
          })
        : () =>
            walletSendVerificationCode({
              sendMethod: this.verifyMethod,
              type: this.type,
              ...this.data,
            });
      this.yzm = await pr()
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
      if (!this.yzm) {
        return;
      }
      this.time = 60 * 1000;
    },
    clear() {
      this.time = 0;
      this.yzm = "";
    },
  },
  watch: {
    type: {
      immediate: true,
      handler(val) {
        if (["", null, undefined].includes(val)) return;
        const fItem = validMethods.find((item) => {
          return item.codes.includes(+val);
        });
        this.verifyMethod = fItem.sendMethod;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.valid-methods {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.valid-methods-hide {
    width: 0;
    height: 0;
    opacity: 0;
    visibility: 0;
    overflow: hidden;
  }
}
</style>
