<template>
  <div class="base-alert">
    <van-popup
      v-model="showPanel"
      round
      position="bottom"
      get-container="#app"
      :closeable="close"
      @close="$emit('close')"
      close-icon="close"
    >
      <div class="base-alert-popup" v-if="showPanel">
        <div class="base-alert-title" v-if="title">{{ title }}</div>
        <slot></slot>
        <div
          v-if="confirmBtn"
          class="base-alert-btn-wrap"
          :class="{
            'base-alert-btn-wrap-inner': innerBtn,
          }"
        >
          <van-button type="info" block v-if="confirmBtn" @click="_submit()">
            {{ confirmBtn }}
          </van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    close: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    innerBtn: {
      type: Boolean,
      default: false,
    },
    confirmBtn: {
      type: [String, Boolean],
      default: false,
    },
    submit: {
      type: [Function],
      default: (next) => next(),
    },
  },
  data() {
    return {
      showPanel: false,
      loading: false,
    };
  },
  methods: {
    show() {
      this.showPanel = true;
    },
    hide() {
      this.showPanel = false;
    },
    _submit() {
      this.loading = true;
      const callBack = () => {
        this.loading = false;
        this.hide();
      };
      this.submit(callBack);
    },
  },
};
</script>

<style scoped lang="scss">
.base-alert-popup {
  padding-top: 12px;
  .base-alert-title {
    font-size: 18px;
    font-weight: 600;
    color: #030319;
    line-height: 27px;
    text-align: center;
    margin-bottom: 10px;
  }
  .base-alert-btn-wrap {
    padding-bottom: 30px;
    &.base-alert-btn-wrap-inner {
      padding-left: 28px;
      padding-right: 28px;
    }
  }
}
</style>
