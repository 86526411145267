<template>
  <div ref="text" class="text-hms" :title="cont">
    <span class="text-hms-inner"> {{ contStr }} <slot></slot> </span>
    <span ref="textInner" class="text-hms-inner text-hms-hidden">
      {{ cont }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    cont: {
      type: [String, Number],
      default: "",
    },
    wrap: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPartNum0: 0,
      showPartNum1: 0,
      resizeObserver: null,
    };
  },
  computed: {
    contStr() {
      if ([null, undefined, ""].includes(this.cont)) {
        return "";
      }
      const length = this.cont.length;
      if (length < this.showPartNum0 + this.showPartNum0 + 1) {
        return this.cont;
      }
      return `${this.cont.slice(0, this.showPartNum0)}...${this.cont.slice(
        length - this.showPartNum0 + 1,
        length
      )}`;
    },
  },
  watch: {
    cont: {
      immediate: true,
      handler() {
        this.countShowNum();
      },
    },
  },
  mounted() {
    this.addListener();
  },
  activated() {
    this.addListener();
  },
  beforeDestroy() {
    this.removeListener();
  },
  deactivated() {
    this.removeListener();
  },
  methods: {
    addListener() {
      this.resizeObserver = new ResizeObserver(() => {
        this.countShowNum();
      });
      // 监听对应的dom
      this.resizeObserver.observe(this.$refs.text);
    },
    removeListener() {
      this.resizeObserver.unobserve(this.$refs.text);
    },
    countShowNum() {
      this.$nextTick(() => {
        const textNum = (this.cont + "").split("").length;
        const boxW = this.$refs.text.getBoundingClientRect().width;
        const innerW = this.$refs.textInner.getBoundingClientRect().width;
        if (innerW < boxW) {
          this.showPartNum0 = Math.ceil(textNum / 2);
          this.showPartNum1 = Math.ceil(textNum / 2);
          return;
        }
        const rate = boxW / innerW;
        const hasOver = innerW % boxW;
        const result = ((textNum * rate) / 2) | 0;
        this.showPartNum0 = result;
        this.showPartNum1 = hasOver ? result - 1 : result;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-hms {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  .text-hms-inner {
    white-space: nowrap;
    &.text-hms-hidden {
      position: absolute;
      left: 0;
      height: 0;
      line-height: 0;
      overflow: hidden;
      opacity: 0;
    }
  }
}
</style>
