var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":_vm.transition ? (_vm.transition === 1 ? 'slide-left' : 'slide-right') : ''},on:{"after-leave":function($event){return _vm.$emit('aniEnd')}}},[(_vm.viewIf)?_c('div',{staticClass:"base-page",class:{
      'base-page-tab': _vm.$route.meta && _vm.$route.meta.tab,
      'base-page-header': _vm.$route.meta && _vm.$route.meta.tabHeader,
      transition: _vm.transition,
      'no-bg': _vm.noBg,
    }},[(_vm.navBar)?_c('van-nav-bar',{attrs:{"title":!_vm.noTitle
          ? _vm.title ||
            _vm.$t(
              _vm.i18nTitle ||
                (this.$route &&
                  this.$route.meta &&
                  this.$route.meta.i18nTitle)
            )
          : ''},on:{"click-left":() => {
          _vm.navBarClickLeft ? _vm.navBarClickLeft() : _vm.back();
        }},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('div',{staticClass:"nav-icon-wrap"},[_c('svg-icon',{staticClass:"nav-icon",attrs:{"iconClass":"back"}})],1)]},proxy:true}],null,false,46680027)}):_vm._e(),_c('div',{staticClass:"base-page-inner",class:{
        'nav-bar': _vm.navBar,
      }},[_vm._t("default",null,{"data":{ ..._vm.$route.meta }})],2)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }