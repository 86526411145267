export default {
  ModuleLoading: "加载中...",
  ModuleLoadingFailed: "加载失败",
  Reload: "重新加载",
  return: "返回",

  RefreshSuccessful: `刷新成功`,
  noData: `无数据`,
  close: `关闭`,
  AssetDetails: `资产详情`,
  ViewAssetDetails: `查看资产详情`,
  wallet: `钱包`,
  langTitle: `简体中文`,
  myWallet: `我的钱包`,
  set: `设置`,
  securitySetting: `安全设置`,
  valuation: `计价`,
  addressBook: `地址本`,
  language: `语言`,
  USD: `美金`,
  NTD: `台币`,
  setLangs: `语言设置`,
  setValuations: `计价设置`,
  conversionOfWalletAssets: `钱包资产折合`,
  cancel: `取消`,

  ChooseAWallet: `选择钱包`,
  UnboundInfo: `当前账户未绑定联系方式，为了保障你账户安全`,
  Recharge: `充值`,
  TransferOut: `转出`,
  TransferIn: `转入`,
  exchange: `兑换`,
  asset: `资产`,
  ThereSNoMore: `没有更多了`,
  Loading: `加载中`,
  NoData: `暂无数据`,
  RequestFailedClickToReload: `请求失败，点击重新加载`,
  SelectRechargeCurrency: `选择充值币种`,
  Currency: `币种`,

  RechargePrompt: `充值提示`,
  RechargeCurrency: `充值币种`,
  RechargeQuantity: `充值数量`,
  available: `可用`,
  prompt: `提示`,
  RechargePrompt1: `1、为了加强您的资金安全，输入密码时远离旁人`,
  RechargePrompt2: `2、请仔细选择您的到账网络，否则到账资产不可找回`,
  RechargePrompt3: `3、请务必确认账户安全，防止信息被篡改或泄漏`,

  RechargeTo: `充值到`,
  PleaseSelectACurrency: `请选择币种`,
  PleaseEnterTheQuantityThatNeedsToBeRecharged: `请输入需要充值的数量`,
  InsufficientAvailableBalance: `可用余额不足`,
  ConfirmTransfer: `确认转账`,
  ToAccountNetwork: `到账网络`,
  ArrivalAddress: `到账地址`,
  TransferQuantity: `转账数量`,
  HandlingFees: `手续费`,
  ConfirmRecharge: `确认充值`,
  SecurityVerification: `安全验证`,
  MobileAccountPassword: `动账密码`,
  Send: `发送`,
  SendInfo1: `确保您的收款网络是`,
  SendInfo2: `否则资产将会丢失`,

  PleaseEnterTheDeliveryAddress: `请输入到账地址`,
  PleaseEnterTheCorrectDeliveryAddress: `请输入正确到账地址`,
  ExceededYourCurrentBalance: `已超出您当前余额`,
  NotSetSetUpNow: `未设置动账密码，无法转出资产;`,
  PleaseEnterTheTransferAmount: `请输入转账金额！`,
  PleaseEnterTheTransferAddress: `请输入转账地址！`,

  network: `网络`,
  TransferAmount: `转账数额`,
  MinimumTransferAmount: `最小转账数额`,
  NetworkHandlingFees: `网络手续费`,
  Transfer: `转账`,
  confirm: `确认`,
  ReceivedQuantity: `到账数量`,
  PleaseSelectANetwork: `请选择网络`,
  LengthLimit: `长度限制`,
  Bytes: `个字节`,
  TheAddressIsIllegal: `地址不存在/地址非法，重新输入`,

  SelecttransferCurrency: `选择转账币种`,
  SelectTransferCurrency: `选择转入币种`,

  OnlySupported: `仅支持`,
  OnlineCollection: `网络收款`,
  ewmInfo: `确保您选择的收款网络与您在提币平台使用网络一致，否则资产将会丢失`,
  copy: `复制`,
  SavePicture: `保存图片`,
  SuccessfullyCopied: `复制成功！`,
  SaveQRCode: `保存二维码`,
  pwdErr: `动账密码错误，请重新输入`,

  noPwdInfo: `未设置动账密码，无法转出资产;`,
  noPwdInfoTo: `立即设置`,
  chainType: `链类型`,
  setValuation: `计价`,
  PricingSettings: `计价设置`,
  LanguageSettings: `语言设置`,
  mail: `邮件`,
  mobilePhone: `移动电话`,
  mobileAccountPassword: `动账密码`,
  NewAddressBook: `新建地址本`,
  AddressBookManagement: `地址本管理`,
  address: `地址`,
  name: `名称`,
  Remarks: `备注(选填)`,
  delete: `删除`,
  // confirm: `确认`,
  // PleaseSelectANetwork: `请选择网络`,
  contactInformation: `联系方式`,
  Unbind: `去绑定`,
  AccountSecurity: `账户安全`,
  // ChooseAWallet: `选择钱包`,
  Collection: `收款`,
  // Transfer: `转账`,
  whole: `全部`,
  Entry: `入账`,
  Outgoing: `出账`,
  // exchange: `兑换`,
  fail: `失败`,
  ToBeCompleted: `待完成`,
  Complete: `完成`,
  // Fail: `失败`,
  TransferOutHandlingFee: `转出手续费`,
  ExchangeEntry: `兑换入账`,
  ExchangeOutOfAccount: `兑换出账`,
  ExchangeHandlingFee: `兑换手续费`,
  ApplicationCharging: `应用充提`,
  InitiationTime: `发起时间`,
  State: `状态`,
  TransactionNumber: `交易号`,
  ReceivedQuantity1: `收款数量`,
  OriginatingAddress: `发起地址`,
  TimeOfReceipt: `到账时间`,
  WithdrawalExpenses: `兑出支出`,
  CashInToAccount: `兑入到账`,
  TransactionType: `交易类型`,
  Withdrawal: `提现`,
  InProgress: `进行中`,
  Quantity: `数量`,
  ChainNetwork: `链网络`,
  networkTimeout: `网络超时`,
  noNetwork: `无网络`,
  Email: `邮箱`,
  minAmountErr: `少于最小操作额度`,
  exChange0tips: `请输入兑换所需支出的数量`,
  exChange1tips: `请输入需要兑换的数量`,

  SelectExchangeCurrency: "选择兑换币种",
  ExpectedReceipt: "预计到账",
  ConfirmRedemption: "确认兑换",
  ExchangeCurrencySelection: "兑出币种选择",
  QuantityOfCurrencyExchanged: "兑出币种数量",
  QuantityOfCurrencyTransferred: "兑入币种数量",
  // ExpectedReceipt: "预计到账",
  PleaseSelectTheCurrencyToBeRedeemed: "请选择兑换出的币种",
  PleaseSelectTheCurrencyToExchangeIn: "请选择要换入的币种",

  // ===
  emailSetTitle: `邮箱验证，为您的转账和钱包安全增加保护`,
  BindEmail: `绑定邮箱`,
  ChangeEmail: `更改邮箱`,
  pwdSetTitle: `动账密码，为您的钱包安全增加保护`,
  pwdSetTitleInfo1: `请输入动账密码，用于动账验证`,
  UnboundPrompt: `未绑定提示`,
  SetAccountPassword: `设置动账密码`,
  ChangeAccountPassword: `更改动账密码`,
  ForgotTheAccountPassword: `忘记动账密码`,
  UnboundContactInformation: `当前账户未绑定联系方式，为了保障你账户安全`,
  phoneSetTitle: `移动电话验证，为您的转账和钱包安全增加保护`,
  BindMobilePhone: `绑定移动电话`,
  ChangeMobilePhone: `更改移动电话`,
  EmailVerification: `邮箱验证`,
  ChangeToPhoneNumberVerification: `换成手机号验证`,
  PleaseEnterTheVerificationCode: `请输入验证码`,
  PleaseEnterTheVerificationParts0: `请输入`,
  PleaseEnterTheVerificationParts1: `验证码`,

  MobileVerification: `手机验证`,
  ChangeToEmailVerification: `换成邮箱验证`,
  EmailAddress: `邮箱地址`,
  PleaseEnterAnEmailAddress: `请输入邮箱地址`,
  submit: `提交`,
  PleaseFillInAgainToConfirm: `请再次填写以确认`,
  ReturnInput: `重新设置`,
  PleaseEnterAValidPhoneNumber: `请输入有效手机号`,
  PleaseEnterYourPhoneVerificationCode: `请输入手机验证码`,
  ConfirmSettings: `确认设置`,
  InconsistentPassword: `动账密码不一致`,
  EmailSuccessfullyBound: `邮箱已绑定成功`,
  TheNewEmailHasBeenSuccessfullyChanged: `新邮箱已更改成功`,
  MobilePhoneSuccessfullyBound: `移动电话已绑定成功`,
  NewMobilePhoneHasBeenChanged: `新移动电话已更改成功`,
  TheAccountPasswordHasBeenSuccessfullySet: `动账密码已设置成功`,
  SuccessfullyChangedTheAccountPassword: `动账密码更改成功`,
  SendVerificationCode: `发送验证码`,
  PleaseEnterSearchKeywords: `请输入搜索关键词`,
  SuccessfullyDeleted: `删除成功`,
  SuccessfullyAdded: `添加成功`,
  emailErr: `邮箱格式错误`,
  emptyErr: `不可为空`,
  SelCurrencyList: `选择币种列表`,
  // Collection: `收款`,
  transferAccounts: `转账`,
  SetUp: `设置`,
  NoPwdRechargeInfo: `未设置动账密码，无法发起充值;`,
  NoPwdExchangeInfo: `未设置动账密码，无法发起兑换;`,
  addressCheck: `地址校验`,
  charging: `计费中...`,
  InsufficientHandlingFees: `手续费不足`,
  PleaseEnter48Characters: `请输入48个字节`,
  LoadingFailed: `加载失败`,
  ParsingFailed: `解析失败`,
  PleaseSelectAnAreaCode: "请选择区号",
  pleaseEnter: "请输入",
  numbers: "个数字",
  NextStep: `下一步`,
  passwordAuthentication: `密码验证`,
  ChangeTheAccountPassword: `修改动账密码`,
  ChangeTheAccountPasswordInfo: `输入旧动账密码, 以验证身份`,
  delAddressMsg: `确认是否删除当前地址？`,
  PleaseEnter: `请输入 `,
  PleaseSelect: `请选择`,
  AddressModificationSuccessful: `地址修改成功`,
  CannotBeLessThan: `不可小于`,
  CannotBeGreaterThan: `不可大于`,
  Byte: `字节`,
  RechargeToApplicationAccount: `充值到应用账户`,
  不能向自己錢包轉帳: "不能向自己钱包转账",
  ForgotPassword: "忘记密码",
  VerificationCodeError: `验证码错误`,
  PasswordError: `密码错误`,
  minimumOutput: `最小輸出`,
  share: "分享",
  LongPressToSaveLocally: `长按保存到本地`,
  NoMoreThanDecimalSeparator: `不可超过小数点`,
  position: `位`,
  IntegerBitsCannotExceed: `整数位不可超过`,
  PleaseCorrect: `请修正`,
  MustBeGreaterThan: `必须大于`,
  code0: `发送失败`,
  code1: `发送未知代码错误`,
  code2: `短信发送错误`,
  code3: `验证失败`,
  code4: `验证码错误`,
  code5: `验证码已过期`,
  // code6: `发送频率太高, 请在稍候再试`,
  LinkFailure: `链接失效`,
  code6part1: `发送频率太高, 请在`,
  code6part2: `秒之后再试试`,
  // ExchangeHandlingFee: `兑换手续费`,
  code7: `动账密码错误`,
  inOperation: "操作中",
  InvalidPhoneNumber: `手机号码无效`,
  paste: `粘贴`
};
