export default {
  200: `一切正常`,
  400: `参数缺失或格式错误`,
  401: `apiKey错误`,
  402: `参数格式正确但请求失败 业务错误`,
  403: `请求的资源不存在`,
  500: `服务器出错`,
  1000: `数据库出错`,
  10010: `数据库处理出错`,
  10020: `服务器出错`,
  10030: `请求被限制`,
  10040: `不支持的操作`,
  10050: `参数验证不通过`,
  10060: `参数缺失`,
  11000: `找不到指定的数据`,
  11010: `找不到用户`,
  11020: `找不到Token`,
  11030: `找不到商家项目`,
  11040: `找不到钱包`,
  12000: `手机参数出错`,
  12010: `邮箱参数出错`,
  12020: `未绑定手机`,
  12030: `未绑定邮箱`,
  12040: `手机已被绑定`,
  12050: `邮箱已被绑定`,
  12060: `手机被占用`,
  12070: `邮箱被占用`,
  12080: `数量必须大于0`,
  12090: `输入数量未达到最小数量`,
  12100: `余额不足`,
  12110: `充值失败/异常，请联系客服`,
  12120: `小数位超出限制`,
  11050: `无效的钱包地址`,
};
