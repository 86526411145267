export default [
  { label: "Afghanistan", value: "+93", length: "9", id: "AF" },
  { label: "Albania", value: "+355", length: "9", id: "AL" },
  { label: "Algeria", value: "+213", length: "9", id: "DZ" },
  { label: "American Samoa", value: "+1684", length: "7", id: "AS" },
  { label: "Andorra", value: "+376", length: "9", id: "AD" },
  { label: "Angola", value: "+244", length: "9", id: "AO" },
  { label: "Anguilla", value: "+1264", length: "7", id: "AI" },
  { label: "Antigua and Barbuda", value: "+1268", length: "7", id: "AG" },
  { label: "Argentina", value: "+54", length: "10", id: "AR" },
  { label: "Armenia", value: "+374", length: "8", id: "AM" },
  { label: "Aruba", value: "+297", length: "7", id: "AA" },
  { label: "Australia", value: "+61", length: "9", id: "AU" },
  { label: "Austria", value: "+43", length: "10", id: "AT" },
  { label: "Azerbaijan", value: "+994", length: "9", id: "AZ" },
  { label: "Bahamas", value: "+1242", length: "7", id: "BS" },
  { label: "Balin", value: "+973", length: "8", id: "BH" },
  { label: "Bangladesh", value: "+880", length: "8", id: "BD" },
  { label: "Barbados", value: "+1246", length: "7", id: "BB" },
  { label: "Belarus", value: "+375", length: "9", id: "BY" },
  { label: "Belgium", value: "+32", length: "9", id: "BE" },
  { label: "Belize", value: "+501", length: "7", id: "BZ" },
  { label: "Benin", value: "+229", length: "8", id: "BJ" },
  { label: "Bermuda", value: "+1441", length: "7", id: "BM" },
  { label: "Bhutan", value: "+975", length: "8", id: "BT" },
  { label: "Bolivia", value: "+591", length: "8", id: "BO" },
  { label: "Bosnia and Herzegovina", value: "+387", length: "8|9", id: "BA" },
  { label: "Botswana", value: "+267", length: "8", id: "BW" },
  { label: "Brazil", value: "+55", length: "9", id: "BR" },
  { label: "Brunei", value: "+673", length: "7", id: "BN" },
  { label: "Bulgaria", value: "+359", length: "9", id: "BG" },
  { label: "Burkina Faso", value: "+226", length: "8", id: "BF" },
  { label: "Burundi", value: "+257", length: "8", id: "BI" },
  { label: "Cambodia", value: "+855", length: "8|9", id: "KH" },
  { label: "Cameroon", value: "+237", length: "9", id: "CM" },
  { label: "Canada", value: "+1", length: "10", key: "Canada", id: "CA" },
  { label: "Cape Verde", value: "+238", length: "7", id: "CV" },
  { label: "Cayman Islands", value: "+1345", length: "7", id: "KY" },
  { label: "Central African Republic", value: "+236", length: "8", id: "CF" },
  { label: "Chad", value: "+235", length: "8", id: "TD" },
  { label: "Chile", value: "+56", length: "9", id: "CL" },
  { label: "Mainland China", value: "+86", length: "11", id: "CN" },
  { label: "Columbia", value: "+57", length: "10", id: "CO" },
  { label: "Comoros", value: "+269", length: "7", id: "KM" },
  { label: "Cook Islands", value: "+682", length: "5", id: "CK" },
  { label: "Costa Rica", value: "+506", length: "8", id: "CR" },
  { label: "Cote d 'Ivoire", key: "CoteDIvoire", value: "+225", length: "8", id: 'CI' },
  { label: "Croatia", value: "+385", length: "9", id: "HR" },
  { label: "Curacao", value: "+599", length: "7", id: "CW" },
  { label: "Cyprus", value: "+357", length: "8", id: "CY" },
  { label: "Czech", value: "+420", length: "9", id: "CZ" },
  {
    label: "Democratic Republic of the Congo",
    value: "+243",
    length: "9",
    id: "CD",
  },
  { label: "Denmark", value: "+45", length: "8", id: "DK" },
  { label: "Djibouti", value: "+253", length: "8", id: "DJ" },
  { label: "Dominic", value: "+1767", length: "7", id: "DM" },
  { label: "Dominican Republic", value: "+1809", length: "7", id: "DO" },
  { label: "Ecuador", value: "+593", length: "7", id: "EC" },
  { label: "Egypt", value: "+20", length: "10", id: "EG" },
  { label: "El Salvador", value: "+503", length: "8", id: "SV" },
  { label: "Equatorial Guinea", value: "+240", length: "9", id: "GQ" },
  { label: "Eritrea", value: "+291", length: "7", id: "ER" },
  { label: "Estonia", value: "+372", length: "7|8", id: "EE" },
  { label: "Eswatini", value: "+268", length: "8", id: "SZ" },
  { label: "Ethiopia", value: "+251", length: "9", id: "ET" },
  { label: "Faroe Islands", value: "+298", length: "6", id: "FO" },
  { label: "Fiji", value: "+679", length: "7", id: "FJ" },
  { label: "Finland", value: "+358", length: "9", id: "FI" },
  { label: "France", value: "+33", length: "9", id: "FR" },
  { label: "French Guiana", value: "+594", length: "9", id: "GF" },
  { label: "French Polynesia", value: "+689", length: "8", id: "PF" },
  { label: "Gabon", value: "+241", length: "8", id: "GA" },
  { label: "Gambia", value: "+220", length: "7", id: "GM" },
  { label: "Georgia", value: "+995", length: "9", id: "GE" },
  { label: "Germany", value: "+49", length: "11", id: "DE" },
  { label: "Ghana", value: "+233", length: "9", id: "GH" },
  { label: "Gibraltar", value: "+350", length: "8", id: "GI" },
  { label: "Greece", value: "+30", length: "10", id: "GR" },
  { label: "Greenland", value: "+299", length: "6", id: "GL" },
  { label: "Grenada", value: "+1473", length: "7", id: "GD" },
  { label: "Guadeloupe", value: "+590", length: "9", id: "GP" },
  { label: "Guam", value: "+1671", length: "7", id: "GU" },
  { label: "Guatemala", value: "+502", length: "8", id: "GT" },
  { label: "Guinea", value: "+224", length: "9", id: "GN" },
  { label: "Republic of Guinea-Bissau", value: "+245", length: "9", id: "GW" },
  { label: "Guyana", value: "+592", length: "7", id: "GY" },
  { label: "Haiti", value: "+509", length: "8", id: "HT" },
  { label: "Honduras", value: "+504", length: "8", id: "HN" },
  { label: "Hong Kong, China", value: "+852", length: "8", id: "HK" },
  { label: "Hungary", value: "+36", length: "9", id: "HU" },
  { label: "Iceland", value: "+354", length: "7", id: "IS" },
  { label: "India", value: "+91", length: "10", id: "IN" },
  { label: "Indonesia", value: "+62", length: "10|11", id: "ID" },
  { label: "Iraq", value: "+964", length: "10", id: "IQ" },
  { label: "Ireland", value: "+353", length: "9", id: "IE" },
  { label: "Israel", value: "+972", length: "9", id: "IL" },
  { label: "Italy", value: "+39", length: "10", id: "IT" },
  { label: "Jamaica", value: "+1876", length: "7", id: "JM" },
  { label: "Japan", value: "+81", length: "10", id: "JP" },
  { label: "Jordan", value: "+962", length: "9", id: "JO" },
  { label: "Kazakhstan", value: "+7", length: "10", id: "KZ" },
  { label: "Kenya", value: "+254", length: "9", id: "KE" },
  { label: "Kiribati", value: "+686", length: "7", id: "KI" },
  { label: "Kuwait", value: "+965", length: "8", id: "KW" },
  { label: "Kyrgyzstan", value: "+996", length: "9", id: "KG" },
  { label: "Laos", value: "+856", length: "10", id: "LA" },
  { label: "Latvia", value: "+371", length: "8", id: "LV" },
  { label: "Lebanon", value: "+961", length: "8", id: "LB" },
  { label: "Lesotho", value: "+266", length: "8", id: "LS" },
  { label: "Liberia", value: "+231", length: "9", id: "LR" },
  { label: "Libya", value: "+218", length: "8", id: "LY" },
  { label: "Liechtenstein", value: "+423", length: "7", id: "LI" },
  { label: "Lithuania", value: "+370", length: "8", id: "LT" },
  { label: "Luxembourg", value: "+352", length: "9", id: "LU" },
  { label: "Macau, China", value: "+853", length: "8", id: "MO" },
  { label: "North Macedonia", value: "+389", length: "8", id: "MK" },
  { label: "Madagascar", value: "+261", length: "9", id: "MG" },
  { label: "Malawi", value: "+265", length: "9", id: "MW" },
  { label: "Malaysia", value: "+60", length: "9|10", id: "MY" },
  { label: "Maldives", value: "+960", length: "7", id: "MV" },
  { label: "Malian", value: "+223", length: "8", id: "ML" },
  { label: "Malta", value: "+356", length: "8", id: "MT" },
  { label: "Martinique", value: "+596", length: "9", id: "MQ" },
  { label: "Mauritania", value: "+222", length: "8", id: "MR" },
  { label: "Mauritius", value: "+230", length: "8", id: "MU" },
  { label: "Mayotte", value: "+262", length: "9", key: "Mayotte", id: "YT" },
  { label: "Mexico", value: "+52", length: "10", id: "MX" },
  { label: "Moldova", value: "+373", length: "8", id: "MD" },
  { label: "Monaco", value: "+377", length: "8", id: "MC" },
  { label: "Mongolia", value: "+976", length: "8", id: "MN" },
  { label: "Black Mountain", value: "+382", length: "8", id: "ME" },
  { label: "Montserrat Island", value: "+1664", length: "7", id: "MS" },
  { label: "Morocco", value: "+212", length: "9", id: "MA" },
  { label: "Mozambique", value: "+258", length: "9", id: "MZ" },
  { label: "Burma", value: "+95", length: "10", id: "MM" },
  { label: "Namibia", value: "+264", length: "9", id: "NA" },
  { label: "Nepal", value: "+977", length: "10", id: "NP" },
  { label: "Holland", value: "+31", length: "9", id: "NL" },
  { label: "New Caledonia", value: "+687", length: "6", id: "NC" },
  { label: "New Zealand", value: "+64", length: "8|9|10", id: "NZ" },
  { label: "Nicaragua", value: "+505", length: "8", id: "NI" },
  { label: "Niger", value: "+227", length: "8", id: "NE" },
  { label: "Nigeria", value: "+234", length: "10", id: "NG" },
  { label: "Norway", value: "+47", length: "8", id: "NO" },
  { label: "Oman", value: "+968", length: "8", id: "OM" },
  { label: "Pakistan", value: "+92", length: "10", id: "PK" },
  { label: "Palau", value: "+680", length: "7", id: "PW" },
  { label: "Palestine", value: "+970", length: "9", id: "BL" },
  { label: "Panama", value: "+507", length: "8", id: "PA" },
  { label: "Papua New Guinea", value: "+675", length: "8", id: "PG" },
  { label: "Paraguay", value: "+595", length: "9", id: "PY" },
  { label: "Peru", value: "+51", length: "9", id: "PE" },
  { label: "Philippines", value: "+63", length: "10", id: "PH" },
  { label: "Poland", value: "+48", length: "9", id: "PL" },
  { label: "Portugal", value: "+351", length: "9", id: "PT" },
  { label: "Puerto Rico", value: "+1787", length: "7", id: "PR" },
  { label: "Qatar", value: "+974", length: "8", id: "QA" },
  { label: "Republic of Congo", value: "+242", length: "9", id: "CG" },
  { label: "Reunion", value: "+262", length: "9", key: "Reunion", id: "RE" },
  { label: "Romania", value: "+40", length: "9", id: "RO" },
  { label: "Rwanda", value: "+250", length: "9", id: "RW" },
  {
    label: "St. Kitts and Nevis",
    key: "StKittsAndNevis",
    value: "+1869",
    length: "7",
    id: "KN",
  },
  { label: "Santa Lucia", value: "+1758", length: "7", id: "LC" },
  {
    label: "St. Pierre and Miquelon Island",
    key: "StPierreAndMiquelonIsland",
    value: "+508",
    length: "6",
    id: "PM",
  },
  {
    label: "St. Vincent and the Grenadines",
    key: "StVincentAndTheGrenadines",
    value: "+1784",
    length: "7",
    id: "VC",
  },
  { label: "Samoa", value: "+685", length: "7", id: "WS" },
  { label: "SAN Marino", value: "+378", length: "8", id: "SM" },
  { label: "SAO Tome and Principe", value: "+239", length: "7", id: "ST" },
  { label: "Saudi Arabia", value: "+966", length: "9", id: "SA" },
  { label: "Senegal", value: "+221", length: "7", id: "SN" },
  { label: "Serbia", value: "+381", length: "9" },
  { label: "Seychelles", value: "+248", length: "7", id: "SC" },
  { label: "Sierra Leone", value: "+232", length: "8", id: "SL" },
  { label: "Singapore", value: "+65", length: "8", id: "SG" },
  {
    label: "St. Maarten Island (Dutch part)",
    key: "StMaartenIslandDutchPart",
    value: "+1721",
    length: "7",
    id:"MF"
  },
  { label: "Slovakia", value: "+421", length: "9", id: "SK" },
  { label: "Slovenia", value: "+386", length: "8", id: "SI" },
  { label: "Solomon Islands", value: "+677", length: "7", id: "SB" },
  { label: "Somalia", value: "+252", length: "9", id: "SO" },
  { label: "South Africa", value: "+27", length: "9", id: "ZA" },
  { label: "Korea", value: "+82", length: "10", id: "KR" },
  { label: "Spain", value: "+34", length: "9", id: "ES" },
  { label: "Sri Lanka", value: "+94", length: "9", id: "LK" },
  { label: "Suriname", value: "+597", length: "7", id: "SR" },
  { label: "Sweden", value: "+46", length: "9", id: "SE" },
  { label: "Switzerland", value: "+41", length: "9", id: "CH" },
  {
    label: "Taiwan, China",
    key: "TaiwanChina",
    value: "+886",
    length: "9",
    id: "TW",
  },
  { label: "Tajikistan", value: "+992", length: "9", id: "TJ" },
  { label: "Tanzania", value: "+255", length: "9", id: "TZ" },
  { label: "Thailand", value: "+66", length: "9", id: "TH" },
  { label: "East Timor", value: "+670", length: "8", id: "TP" },
  { label: "Togo", value: "+228", length: "8", id: "TG" },
  { label: "Tonga", value: "+676", length: "7", id: "TO" },
  { label: "Trinidad and Tobago", value: "+1868", length: "7", id: "TT" },
  { label: "Tunisia", value: "+216", length: "8", id: "TN" },
  { label: "Turkey", value: "+90", length: "10", id: "TR" },
  { label: "Turkmenistan", value: "+993", length: "8", id: "TM" },
  { label: "Turks and Caicos Islands", value: "+1649", length: "7", id: "TC" },
  { label: "Uganda", value: "+256", length: "9", id: "UG" },
  { label: "Ukraine", value: "+380", length: "9", id: "UA" },
  { label: "United Arab Emirates", value: "+971", length: "9", id: "AE" },
  { label: "UK", value: "+44", length: "10", id: "GB" },
  {
    label: "United States",
    value: "+1",
    length: "10",
    key: "UnitedStates",
    id: "US",
  },
  { label: "Uruguay", value: "+598", length: "8", id: "UY" },
  { label: "Uzbekistan", value: "+998", length: "9", id: "UZ" },
  { label: "Vanuatu", value: "+678", length: "7", id: "VU" },
  { label: "Venezuela", value: "+58", length: "10", id: "VE" },
  { label: "Vietnam", value: "+84", length: "9", id: "VN" },
  { label: "British Virgin Islands", value: "+1284", length: "7", id: "VG" },
  {
    label: "United States Virgin Islands",
    value: "+1340",
    length: "7",
    id: "VI",
  },
  { label: "Yemen", value: "+967", length: "9", id: "YE" },
  { label: "Zambia", value: "+260", length: "9", id: "ZM" },
  { label: "Zimbabwe", value: "+263", length: "9", id: "ZW" },
];
