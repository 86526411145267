<template>
  <transition
    :name="transition ? (transition === 1 ? 'slide-left' : 'slide-right') : ''"
    @after-leave="$emit('aniEnd')"
  >
    <div
      class="base-page"
      v-if="viewIf"
      :class="{
        'base-page-tab': $route.meta && $route.meta.tab,
        'base-page-header': $route.meta && $route.meta.tabHeader,
        transition: transition,
        'no-bg': noBg,
      }"
    >
      <van-nav-bar
        v-if="navBar"
        :title="
          !noTitle
            ? title ||
              $t(
                i18nTitle ||
                  (this.$route &&
                    this.$route.meta &&
                    this.$route.meta.i18nTitle)
              )
            : ''
        "
        @click-left="
          () => {
            navBarClickLeft ? navBarClickLeft() : back();
          }
        "
      >
        <template #left>
          <div class="nav-icon-wrap">
            <svg-icon iconClass="back" class="nav-icon"></svg-icon>
          </div>
        </template>
      </van-nav-bar>
      <div
        class="base-page-inner"
        :class="{
          'nav-bar': navBar,
        }"
      >
        <slot :data="{ ...$route.meta }"></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "BasePage",
  props: {
    navBarClickLeft: {
      type: Function,
    },
    viewIf: {
      type: Boolean,
      default: true,
    },
    transition: {
      type: Number,
      default: 0,
    },
    noBg: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: Boolean,
      default: false,
    },
    navBar: {
      type: [Boolean],
      default: false,
    },
    title: {
      type: [String],
      default: "",
    },
    i18nTitle: {
      type: [String],
      default: "",
    },
    noTitle: {
      type: [Boolean],
      default: false,
    },
  },
  methods: {
    back() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";

.base-page {
  &.transition {
    transition: all 0.5s;
  }
}
.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active,
.slide-right-enter {
  opacity: 1;
  transform: translate3d(-100%, 0, 0);
}

.base-page {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  &.base-page-header {
    padding-top: 46px;
  }
  &.base-page-tab {
    padding-bottom: 50px;
  }
  .base-page-inner {
    margin-top: -1px;
    position: relative;
    height: 100%;
    width: 100%;
    &.nav-bar {
      height: calc(100% - 45px);
    }
  }
}
.nav-icon {
  font-size: 22px;
}
.nav-icon-wrap {
  @include svgShadow(#fff);
  .svg-icon {
    font-size: 30px;
  }
}
.base-page.no-bg {
  .van-nav-bar {
    background-color: transparent !important;
  }

  ::v-deep {
    .van-nav-bar .van-nav-bar__title {
      color: #123c3c !important;
    }
    .nav-icon-wrap {
      @include svgShadow(#123c3c);
    }
  }
}
</style>
