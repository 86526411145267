import Cookies from "js-cookie";
import lodash from "lodash";
import {
  getChainTokenList,
  walletGetChainList,
  walletAddressList,
  walletGetValidTransferTokens,
} from "@/api/wallet.js";
import { getCurrency } from "@/api/user";
import { removeToken } from "@/utils/auth";
import $NP from "@/utils/NP";

const currencys = [
  {
    currency: "USD",
    rate: 1,
    symbol1: "$",
    symbol2: "US$",
    key: 1,
    i18nName: "USD",
  },
  {
    currency: "NTD",
    rate: 0.0327,
    symbol1: "NT$",
    symbol2: "NT$",
    key: 886,
    i18nName: "NTD",
  },
];
const state = {
  sidebar: {
    opened: Cookies.get("sidebarStatus")
      ? !!+Cookies.get("sidebarStatus")
      : true,
    withoutAnimation: false,
  },
  loading: false,
  currencys:
    (Cookies.get("currencys") && JSON.parse(Cookies.get("currencys"))) ||
    currencys,
  curCurrency:
    (Cookies.get("curCurrency") && JSON.parse(Cookies.get("curCurrency"))) ||
    currencys[0],
  tokens: (Cookies.get("tokens") && JSON.parse(Cookies.get("tokens"))) || [],
  ct_tokens:
    (Cookies.get("ct_tokens") && JSON.parse(Cookies.get("ct_tokens"))) || {},
  walletChains:
    (Cookies.get("walletChains") && JSON.parse(Cookies.get("walletChains"))) ||
    [],
  curWalletChain:
    (Cookies.get("curWalletChain") &&
      JSON.parse(Cookies.get("curWalletChain"))) ||
    "",
  addressList:
    (Cookies.get("addressList") && JSON.parse(Cookies.get("addressList"))) ||
    "",
  alert: "",
  cViews: [],
};

const mutations = {
  TOGGLE_SIDEBAR: (state) => {
    state.sidebar.opened = !state.sidebar.opened;
    if (state.sidebar.opened) {
      Cookies.set("sidebarStatus", 1);
    } else {
      Cookies.set("sidebarStatus", 0);
    }
  },
  SET_CURRENCYS: (state, currencys) => {
    state.currency = [...(currencys || [])];
    Cookies.set("currencys", JSON.stringify(state.currency));
  },
  SEL_CURRENCY: (state, curCurrency) => {
    state.curCurrency = curCurrency;
    Cookies.set("curCurrency", JSON.stringify(state.curCurrency));
  },
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SEL_TOKENS: (state, tokens) => {
    state.tokens = tokens;
    Cookies.set("tokens", JSON.stringify(state.tokens));
  },
  SET_WALLET_CHAINS: (state, walletChains) => {
    state.walletChains = walletChains;
    Cookies.set("walletChains", JSON.stringify(state.walletChains));
  },
  SEL_WALLET_CHAIN: (state, walletChainItem) => {
    if(walletChainItem){
      state.curWalletChain = walletChainItem;
      Cookies.set("curWalletChain", JSON.stringify(state.curWalletChain));
    }else{
      state.curWalletChain = '';
      Cookies.remove("curWalletChain", JSON.stringify(state.curWalletChain));
    }
  },
  SET_ADDRESS_LIST: (state, addressList) => {
    state.addressList = addressList;
    Cookies.set("addressList", JSON.stringify(state.addressList));
  },
  SET_ALERT: (state, data) => {
    state.alert = data
      ? {
          msg: data.msg,
          func: data.func,
          toMsg: data.toMsg,
        }
      : "";
  },
  SEL_CT_TOKENS: (state, tokens) => {
    state.ct_tokens = tokens;
    Cookies.set("ct_tokens", JSON.stringify(state.ct_tokens));
  },
  SET_CACHE: (state, { viewName = "", st = 1 } = {}) => {
    const has = state.cViews.some((item) => item === viewName);
    if (st) {
      !has && state.cViews.push(viewName);
    } else {
      if (!has) {
        return;
      }
      const idx = state.cViews.findIndex((item) => item === viewName);
      state.cViews.splice(idx, 1);
    }
  },
  CLEAR_COOCIES: (state, st = false) => {
    state.sidebar = {
      opened: true,
      withoutAnimation: false,
    };
    state.currencys = currencys;
    state.curCurrency = currencys[0];
    state.tokens = [];
    state.ct_tokens = {};
    state.walletChains = [];
    state.addressList = "";
    // document.cookie = "";
    Cookies.remove("sidebarStatus");
    Cookies.remove("currencys");
    Cookies.remove("curCurrency");
    Cookies.remove("tokens");
    Cookies.remove("walletChains");
    Cookies.remove("addressList");
    Cookies.remove("ct_tokens");
    if(st){
      state.curWalletChain = "";
      Cookies.remove("curWalletChain");
      removeToken()
    }
  },
};

const actions = {
  toggleSideBar({ commit }) {
    commit("TOGGLE_SIDEBAR");
  },
  setLoading({ commit }, loading) {
    commit("SET_LOADING", loading);
  },
  setCurrencys({ commit }, currencys) {
    commit("SET_CURRENCYS", currencys);
  },
  selCurrency({ commit }, curCurrency) {
    commit("SEL_CURRENCY", curCurrency);
  },
  selTokens({ commit }, tokens) {
    commit("SEL_TOKENS", tokens);
  },
  selWalletChain({ commit }, walletChainItem) {
    commit("SEL_WALLET_CHAIN", walletChainItem);
  },
  async getChainTokenList({ commit }, data) {
    const result = await getChainTokenList(data).catch(() => {
      commit("SEL_TOKENS", []);
    });
    if (!result) return [];
    const toNum = (val)=>[undefined, null, ''].includes(val)?0:+val
    const assetIsEmpty = result.data.every(item=>{
      const isEmpty = +$NP.times(toNum(item.uPrice), toNum(item.balance))
      return !isEmpty
    })
    let tokens = []
    if(!assetIsEmpty){
      tokens = result.data.sort((a, b)=>{
        const aMoney = $NP.times(toNum(a.uPrice), toNum(a.balance))
        const bMoney = $NP.times(toNum(b.uPrice), toNum(b.balance))
        return toNum(aMoney)>toNum(bMoney)?-1:1
      });
    }else{
      tokens = result.data.sort((a, b)=>{
        return (a.orderIndex-b.orderIndex)
      })
    }
    commit("SEL_TOKENS", tokens);
    return result.data;
  },
  async getCTTokens({ commit }, data) {
    const res = await walletGetValidTransferTokens(data).catch(() => {
      commit("SEL_CT_TOKENS", {});
    });
    if (!res) return {};
    const list2Obj = res.data;
    commit("SEL_CT_TOKENS", list2Obj);
    return res.data;
  },
  async getCurrencyFunc({ commit, state }, data) {
    const result = await getCurrency(data).catch(() => {
      commit("SET_CURRENCYS", []);
    });
    if (!result) return [];
    const currencys = result.data;
    if (lodash.isEmpty(state.quoteCurrency) && lodash.isArray(currencys)) {
      for (const currency of currencys) {
        if (
          currency.currency === "USD" &&
          ["", null, undefined].includes(state.curCurrency)
        ) {
          commit("SEL_CURRENCY", currency);
        }
      }
    }
    commit("SET_CURRENCYS", currencys);
    return result.data;
  },
  async getWalletGetChainList({ commit, rootGetters, state }) {
    const result = await walletGetChainList({
      uuid: rootGetters.user ? rootGetters.user.uuid : "",
    }).catch(() => {
      commit("SET_WALLET_CHAINS", []);
      commit("SEL_WALLET_CHAIN", "");
    });
    if (!result) return [];
    const walletChains = result.data || [];
    commit("SET_WALLET_CHAINS", walletChains);
    if (["", null, undefined].includes(state.curWalletChain)) {
      commit("SEL_WALLET_CHAIN", walletChains[0]);
    }
    return result.data;
  },
  async getWalletAddressList({ commit }, data) {
    const result = await walletAddressList(data).catch(() => {
      commit("SET_ADDRESS_LIST", []);
    });
    if (!result) return [];
    const addressList = result.data || [];
    commit("SET_ADDRESS_LIST", addressList);
    return result.data;
  },
  setAlert({ commit }, data) {
    commit("SET_ALERT", data);
  },
  setCView({ commit }, viewName) {
    commit("SET_CACHE", viewName);
  },
  clearCookies({ commit}, st) {
    if(st){
      commit('user/REMOVE_USER', {}, { root: true })
    }
    commit("CLEAR_COOCIES", st);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
