<template>
  <div
    class="wjmm-text"
    @click="
      $router.push({
        name: 'verificationPagePwd',
      })
    "
  >
    {{ $t("ForgotPassword") }}
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.wjmm-text {
  color: #1989fa;
}
</style>
