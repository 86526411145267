<template>
  <div>
    <ListSelects
      ref="alert"
      :title="$t('PricingSettings')"
      :data="
        appCurrencys.map((item) => {
          return {
            name: `${$t(item.i18nName)}-${item.currency}`,
            val: item.key,
            data: item,
          };
        })
      "
      :default-val="appCurCurrency.key"
      @change="select"
      v-slot="{ item }"
    >
      {{ `${item.data.currency}-${$t(item.data.i18nName)}` }}
    </ListSelects>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters(["appCurrencys", "appCurCurrency"]),
  },
  mounted() {
    this.$emit("change", { ...this.appCurCurrency });
  },
  methods: {
    ...mapActions("app", ["selCurrency"]),
    select(val) {
      const fItem = this.appCurrencys.find((item) => item.key === val);
      this.selCurrency(fItem);
      this.$emit("change", { ...fItem });
    },
    show() {
      this.$refs.alert.show();
    },
    hide() {
      this.$refs.alert.hide();
    },
  },
};
</script>

<style scoped></style>
