const transferKey = 'transferData'

function getTransferSession(){
  const dataStr = sessionStorage.getItem(transferKey)||''
  return dataStr?JSON.parse(dataStr):[]
}

function setTransferSession(data){
  sessionStorage.setItem(transferKey, JSON.stringify(data))
}

function debounce(fn, delay=500){
  let timer
  return function (){
    timer&&clearTimeout(timer)
    timer = setTimeout(()=>{
      fn&&fn()
    }, delay)
  }
}

function destroyPreTransfer(){
  const data = getTransferSession()
  data.shift()
  setTransferSession(data)
}

function destroyTransfer(){
  sessionStorage.removeItem(transferKey)
}

export const Mixin = {
  methods: {
    $getTransfer(){
      const data = getTransferSession()
      return data.length?data.pop():{}
    },
    $transferData(setData){
      let data = getTransferSession()
      if(data.length<=1){
        data = [{}, setData]
      }else{
        data[1] = setData
      }
      setTransferSession(data)
    },
  },
  deactivated() {
    debounce(destroyPreTransfer)()
  },
  beforeDestroy() {
    debounce(destroyPreTransfer)()
  }
}

export const AppMixin = {
  beforeDestroy() {
    destroyTransfer()
  }
}