export default {
  200: `一切正常`,
  400: `參數缺失或格式錯誤`,
  401: `apiKey錯誤`,
  402: `參數格式正確但請求失敗 業務錯誤`,
  403: `請求的資源不存在`,
  500: `伺服器出錯`,
  1000: `數據庫出錯`,
  10010: `數據庫處理出錯`,
  10020: `伺服器出錯`,
  10030: `請求被限制`,
  10040: `不支持的操作`,
  10050: `參數驗證不通過`,
  10060: `參數缺失`,
  11000: `找不到指定的數據`,
  11010: `找不到用戶`,
  11020: `找不到Token`,
  11030: `找不到商家項目`,
  11040: `找不到錢包`,
  12000: `手機參數出錯`,
  12010: `郵箱參數出錯`,
  12020: `未綁定手機`,
  12030: `未綁定郵箱`,
  12040: `手機已被綁定`,
  12050: `郵箱已被綁定`,
  12060: `手機被佔用`,
  12070: `郵箱被佔用`,
  12080: `數量必須大於0`,
  12090: `輸入數量未達到最小數量`,
  12100: `餘額不足`,
  12110: `充值失敗/異常，請聯繫客服`,
  12120: `小數位超出限制`,
  11050: `無效的錢包地址`,
};
